<template>
  <div class="actions__slider">
    <div class="container actions__container">
      <div ref="swiper" class="swiper">
        <h2 class="actions__title">{{title}}</h2>
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="action in actions" :key="action.id">
            <router-link :to="{name: componentsPath, params: {id: action.id, actionProps: action, titlePage: title}}">
              <div class="actions__card">
                <img
                  class="actions__image"
                  :src="action.img"
                  :alt="action.name">
                <div class="actions__info">
                  <h4 class="actions__name">{{ action.name }}</h4>
                  <p class="actions__text">{{ action.description }}</p>
                  <span class="actions__more">Подробнее</span>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
        <div class="swiper-scrollbar"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper, {Navigation} from 'swiper'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'SliderPromotional',
  components: {
  },
  props: {
    iterableProducts: {
      type: Array,
      default() {
        return []
      }
    },
    title: {
      type: String,
      default() {
        return ''
      }
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'actions'
    ]),
    componentsPath() {
      return this.title === 'Акции' ? 'actions' : 'articles'
    }
  },
  methods: {
    ...mapActions([
      'GET_ACTIONS'
    ]),
  },
  created() {
    this.GET_ACTIONS();

  },
  mounted() {
    new Swiper(this.$refs.swiper, {
      modules: [Navigation],
      loop: false,
      slidesPerView: 4,
      pagination: {
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        300: {
          slidesPerView: 2,
          spaceBetween: 5,
          slideToClickedSlide: true,
        },
        500: {
          slidesPerView: 2,
          spaceBetween: 5,
          slideToClickedSlide: true,
        },
        800: {
          slidesPerView: 3,
          spaceBetween: 0,
          slideToClickedSlide: true,
        },
        1100: {
          slidesPerView: 4,
          spaceBetween: 0,
          slideToClickedSlide: true,
        }
      },
      scrollbar: {
        el: '.swiper-scrollbar',
      },
    })
  },
}
</script>

<style scoped lang="scss">
.actions {
  &__slider {
    margin-top: 8em;
    @include phone {
      margin-top: 2em;
    }
  }
  &__container {
    padding: 0 15px;
  }
  &__title {
    font-size: 2.5em;
    font-weight: 400;
    margin-bottom: 36px;
    @include phone {
      font-size: 24px;
      font-weight: 500;
    }
  }
  &__card {
    width: 260px;
    height: 322px;
    background-color: $main-dark;
    border-radius: 10px;
    overflow: hidden;
    @include medium-tablet {
      width: 260px;
    }
    @include tablet {
      width: 260px;
    }
    @include phone {
      margin: 0 3px;
      width: 170px;
      height: 307px;
    }
  }
  &__image {
    width: 100%;
    height: 163px;
    @include phone {
      height: 143px;
    }
  }
  &__info {
    padding: 16px 20px;
  }
  &__name {
    display: inline-block;
    font-size: 18px;
  }
  &__text {
    max-height: 54px;
    margin: 16px 0;
    line-height: 18.07px;
    color: #B0B0B0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__more {
    font-weight: 500;
    line-height: 18px;
    color: $orange-color;
  }
}
.swiper-slide {
  display: flex;
  justify-content: center;
}
.swiper-button-prev {
  color: white;
  margin: 0 -12px;
  font-size: 20px;
  @include tablet {
    margin: 0 -17px;
    top: 50%;
    left: 3%;
  }
  &::after {
    font-size: 30px;
    @include tablet {
      font-size: 20px;
    }
  }
}
.swiper-button-next {
  color: white;
  margin: 0 -12px;
  @include tablet {
    margin: 0 -17px;
    top: 50%;
    right: 3%;
  }
  &::after {
    font-size: 30px;
    @include tablet {
      font-size: 20px;
    }
  }
}
</style>





















