import axios from 'axios';
import router from '@/router/index'

const api = axios.create({
  baseURL: "https://api.taroshop.kz/",
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": "ru",
  },
});

const fetchWithAuth = axios.create({
  baseURL: "https://api.taroshop.kz/",
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": "ru",
  },
});

fetchWithAuth.interceptors.request.use(config => {
  if(localStorage.getItem('access_token')) {
    config.headers.authorization = `Bearer ${localStorage.getItem('access_token')}`
  }
  return config;
}, error => {})


fetchWithAuth.interceptors.response.use(config => {
  if(localStorage.getItem('access_token')) {
    config.headers.authorization = `Bearer ${localStorage.getItem('access_token')}`
  }
  return config;
}, error => {
  if (error.response.status === 401 || error.response.data.error === 'Token has expired') {
    return api.post('api/auth/refresh', {}, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('refresh_token')}`
      }
    }).then(res => {
      localStorage.setItem('access_token', res.data.access_token)
      localStorage.setItem('refresh_token', res.data.refresh_token)

      error.config.headers.authorization = `Bearer ${res.data.access_token}`

      return fetchWithAuth.request(error.config)
    })
      .catch(error => {
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        router.push({name: 'login'});
      })
  }
  if (error.response.status === 500) {
    console.log('Ошибка сервера 500', error.response.data.error)
  }
  if (error.response.status === 422) {
     throw error.response.data;
  }
})

export {api, fetchWithAuth}

