<template>
  <div class="rating-note">
    <p class="rating-note__text">
      Вы оптовый покупатель. Сумма минимального заказа должна быть не менее <span>100 тыс</span>
    </p>
  </div>

</template>

<script>
export default {
  name: 'SaleNotificationWholesale',
}
</script>

<style scoped lang="scss">
.rating-note {
  position: absolute;
  z-index: 30;
  width: 180px;
  height: 105px;
  padding: 19px 17px 17px 23px;
  font-size: 12px;
  line-height: 18px;
  color: white;
  background-color: $main-dark;
  border-radius: 10px;
  @include phone {
    width: 151px;
    height: 88px;
    padding: 10px 7px;
  }
  &__text {
    position: relative;
    text-align: left;
    & > span {
      font-weight: 700;
      color: $orange-color;
    }
    &::after {
      content: '';
      position: absolute;
      right: 19px;
      top: -39px;
      border: 12px solid transparent;
      border-bottom: 15px solid $main-dark;
      @include phone {
        right: 10px;
        top: -33px;

      }
    }
  }

}
</style>
