<template>
    <div class="text-block">
        <h2>Добро пожаловать в наш онлайн магазин эзотерических товаров Таро!</h2>

        <p>Раскройте волшебство магии в своей жизни с нашим уникальным ассортиментом Таро, амулетов, кристаллов, свеч, мыла и эзотерических товаров. Наш магазин предлагает вам широкий выбор предметов, которые помогут вам обрести гармонию, укрепить свои духовные практики и открыть тайны окружающего мира. Откройте двери в мир волшебства и загадок прямо сейчас!</p>

        <h2>Наши уникальные особенности:</h2>

        <ol>
            <li>Широкий выбор Таро: В нашем магазине вы найдете разнообразные колоды Таро различных стилей и направлений. Независимо от вашего уровня опыта, эти карты помогут раскрыть ваш потенциал, предсказать будущее и обрести ясность в ваших вопросах.</li>

            <li>Магические свечи: Наши свечи созданы с особым вниманием к деталям и заряжены магической энергией. От ароматических свечей для создания атмосферы уюта до свечей с особыми символами для ритуалов и медитаций, у нас есть все, что вам нужно для создания особенного настроения.</li>

            <li>Эзотерические товары: Исследуйте наш разнообразный выбор эзотерических товаров, включая кристаллы, амулеты, колдовские инструменты и многое другое. Эти предметы помогут вам укрепить вашу практику и привлечь положительную энергию в вашу жизнь.</li>

            <li>Советы и руководства: Мы не только предлагаем вам продукты высокого качества, но и поддерживаем вас на вашем духовном пути. На нашей <a href="https://www.instagram.com/iren_lavka_magic_atrebutov/">instagram странице @iren_lavka_magic_atrebutov</a> вы найдете полезные советы, руководства по использованию Таро и эзотерических товаров, а также информацию о различных ритуалах и техниках.</li>

            <li>Доставка по всему миру: Мы доставляем наши товары в любую точку мира, чтобы каждый мог воспользоваться возможностью обрести магическую мощь и духовное вдохновение, независимо от местоположения.</li>
        </ol>

        <h2>Почему выбирают нас?</h2>

        <p>Качество и оригинальность: Мы работаем только с надежными производителями и поставщиками, чтобы предоставить вам товары высокого качества и аутентичность.</p>

        <p>Доверие и безопасность: Мы гарантируем безопасность вашей покупки и обработку ваших данных с максимальной конфиденциальностью.</p>

        <p>Отзывчивая поддержка клиентов: Наша команда готова помочь вам с любыми вопросами и предоставить профессиональные консультации.</p>

        <p>Постоянные акции и скидки: Мы регулярно предлагаем специальные акции и скидки, чтобы сделать ваши покупки еще более приятными и выгодными.</p>

        <p>Присоединяйтесь к нам сегодня и начните свое путешествие в мир эзотерики и духовного развития! Вас ждут удивительные открытия, вдохновение и волшебство. Откройте двери к своим скрытым потенциалам с нашим онлайн магазином Таро, свечей и эзотерических товаров.</p>
    </div>
</template>

<script>
export default {
    name: 'TextPageBottom',
}
</script>

<style scoped lang="scss">

.text-block {
    margin-top: 58px;
    margin-bottom: 71px;
    @include phone {
        margin: 30px 0;
    }
    & p {
        margin-bottom: 1em;
    }
}
</style>
