<template>
  <div id="app">
    <FeedbackModal />
    <AddNotification />
    <TheHeader />
    <div class="main">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
    <TheFooter />
    <LoginModal />
  </div>
</template>

<script>
import TheHeader from './components/core/TheHeader.vue';
import TheFooter from './components/core/TheFooter.vue';
import AddNotification from "@/components/notification/AddNotification";
import LoginModal from "@/components/auth/LoginModal";
import {mapActions, mapMutations} from 'vuex'
import {fetchWithAuth} from "@/api/api";
import FeedbackModal from "@/components/common/FeedbackModal";
export default {
  name: 'App',
  components: {
    FeedbackModal,
    TheFooter,
    TheHeader,
    AddNotification,
    LoginModal,
  },
  methods: {
    ...mapActions([
      'GET_PRODUCTS',
      'GET_DISCOUNTER',
      'GET_SUPERPRICE',
      'GET_SPECIALOFFER',
      'GET_RECOMMENDED',
      'GET_CATEGORIES',
      'GET_PRODUCTS_CART',
      'GET_TOTAL_PRICE',
    ]),
    ...mapMutations([
      'SET_AUTH_STATUS',
    ]),
  },
  created() {
    this.GET_PRODUCTS();
    this.GET_DISCOUNTER();
    this.GET_SUPERPRICE();
    this.GET_SPECIALOFFER();
    this.GET_RECOMMENDED();
    this.GET_CATEGORIES();
    this.GET_PRODUCTS_CART();
    this.GET_TOTAL_PRICE();
    this.SET_AUTH_STATUS();
  }
}
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>

