<template>
  <div class="v-preloader">
    <img src="@/assets/image/preloader.gif" alt="loading...">
  </div>

</template>

<script>
export default {
  name: 'PreLoader',
  data() {
    return {
      loadingStatuses: {
        loading: 'LOADING',
        ready: 'READY',
        empty: 'EMPTY',
        error: 'ERROR',
      },
    }
  },
}
</script>

<style scoped>
.v-preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  //height: 100%;
  width: 100%;
}

</style>
