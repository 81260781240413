<template>
  <div class="catalog-page">
    <div class="container">
      <div class="catalog__wrapper">
        <TheSidebar/>
        <VProductsList :products="products" :title="`Результат поиска (${products.length})`"/>
      </div>

      <div class="catalog-page__recommend recommend">
        <div class="recommend__buttons">
          <button
            :class="{recommend__button: true, 'active-button': isActive('recommended')}"
            @click="setActiveTab('recommended')"
          >
            Рекомендуем
          </button>
          <button
            :class="{recommend__button: true, 'active-button': isActive('discounted')}"
            @click="setActiveTab('discounted')"
          >
            Скидки
          </button>
          <button
            :class="{recommend__button: true, 'active-button': isActive('super-price')}"
            @click="setActiveTab('super-price')"
          >
            Суперцены
          </button>
          <button
            :class="{recommend__button: true, 'active-button': isActive('special-offer')}"
            @click="setActiveTab('special-offer')"
          >
            Суперпредложения
          </button>
        </div>
        <MainProductSlider v-show="activeTab === 'recommended'" :contentSlider="recommended" />
        <MainProductSlider v-show="activeTab === 'discounted'" :contentSlider="discounted" />
        <MainProductSlider v-show="this.activeTab === 'super-price'"  :contentSlider="superPrice" />
        <MainProductSlider v-show="this.activeTab === 'special-offer'" :contentSlider="specialOffer" />
        <TextPageBottom />
      </div>
    </div>
  </div>
</template>

<script>
import VueMeta from 'vue-meta'
import TheSidebar from "@/components/sidebar/TheSidebar";
import VProductsList from "@/components/catalogue/VProductsList";
import MainProductSlider from "@/components/common/MainProductSlider";
import TextPageBottom from "@/components/common/TextPageBottom";
import { mapGetters } from "vuex";
import {api} from "@/api/api";


export default {
  name: 'ProductsListPage',
  metaInfo() {
    return {
      title: `Эзотерические товары по запросу ${this.$route.query.text}`,
      titleTemplate: '%s | Таро - Онлайн Магазин Эзотерических Товаров',
      htmlAttrs: {
        lang: 'ru',
        amp: true
      },
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: `Весь ассортимент эзотерические товары по запросу ${this.$route.query.text} в Онлайн Магазин Эзотерических Товаров Таро`
        }
      ]
    }
  },
  components: {
    TheSidebar,
    VProductsList,
    MainProductSlider,
    TextPageBottom,
  },
  data() {
    return {
      activeTab: 'recommended',
      products: [],
    }
  },
  computed: {
    ...mapGetters([
      'recommended',
      'discounted',
      'superPrice',
      'specialOffer',
    ]),
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    isActive(tab) {
      return this.activeTab === tab;
    },
    getProductsBySearch() {
      api.get(`api/search?text=${this.$route.query.text}`)
        .then(response => {
          this.products = response.data.data;
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
  watch: {
    '$route.query.text': {
      handler: function() {
        this.getProductsBySearch()
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    console.log('this.$route.query', this.$route.query.text)
    console.log(this.$route)
    this.getProductsBySearch()
  },
  mounted() {
    console.log(this.products)
  }
}
</script>

<style lang="scss">
.catalog {
  &__wrapper {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 78px;
  }
}
.recommend {
  &__buttons {
    display: flex;
  }
  &__button {
    color: white;
    margin-right: 20px;
  }
}
.active-button {
  border-bottom: 2px solid $orange-color;
}
</style>
