<template>
  <section class="cart">
    <div class="container cart__container">
      <slot></slot>
      <div class="cart__wrapper">
        <h3 v-if="cart.length === 0" class="cart__title">В корзине пока пусто</h3>
        <h3 v-if="cart.length > 0" class="cart__title">Корзина</h3>
        <div class="cart__options">
          <div class="cart__all">
            <label class="checkmark">
              <input
                class="checkbox-hidden"
                type="checkbox"
                id="option-all"
                v-model="isCheckedAll"
                @click="checkedAll"
              >
              <div class="checkbox-fake"></div>
              <span class="label-fake">Все товары</span>
            </label>
            <span class="cart__all-count" >Выбрано товаров: {{ checkedProducts.length}}</span>
          </div>
          <div class="cart__move">
            <div class="cart__move-favorite">
              <button @click="addToFavorites">
                <svg width="14.5" height="12.5" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M9 1.881C9.981 0.729 11.484 0 13.05 0C15.822 0 18 2.178
                18 4.95C18 8.34921 14.945 11.1195 10.3164 15.3167L10.305 15.327L9 16.515L7.695 15.336L7.65947 15.3037C3.04437
                11.1098 0 8.3433 0 4.95C0 2.178 2.178 0 4.95 0C6.516 0 8.019 0.729 9 1.881ZM9 14.085L9.09 13.995C13.374
                10.116 16.2 7.551 16.2 4.95C16.2 3.15 14.85 1.8 13.05 1.8C11.664 1.8 10.314 2.691 9.846 3.924H8.163C7.686
                2.691 6.336 1.8 4.95 1.8C3.15 1.8 1.8 3.15 1.8 4.95C1.8 7.551 4.626 10.116 8.91 13.995L9 14.085Z" fill="#DADADA"/>
                </svg>
                <span>В избранное</span>
              </button>
            </div>
            <div class="cart__move-delete">
              <button @click="deleteSelectedProducts">
                <svg width="11.3" height="13.2" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.97222 0H4.25L3.30556 0.944444H0V2.83333H13.2222V0.944444H9.91667L8.97222
                0ZM10.3889 5.66667V15.1111H2.83333V5.66667H10.3889ZM0.944336 3.77783H12.2777V15.1112C12.2777 16.1501
                11.4277 17.0001 10.3888 17.0001H2.83322C1.79434 17.0001 0.944336 16.1501 0.944336 15.1112V3.77783Z" fill="#DADADA"/>
                </svg>
                <span>Удалить</span>
              </button>
            </div>
          </div>
        </div>
        <div class="cart__items">
          <!--ITEM-->
          <div
            class="cart__item"
            v-for="product in cart"
            :key="product.good.id"
          >
            <div class="cart__item-left">
              <label class="checkmark">
                <input
                  class="checkbox-hidden"
                  type="checkbox"
                  :id="product.good.id"
                  :value="product.good.id"
                  v-model="checkedProducts"
                  ref="isCheckProducts"
                  @change="updateCheckAll"
                >
                <div class="checkbox-fake"></div>
              </label>
              <img
                class="cart__img"
                :src="product.good.photos ? product.good.photos[0] : require('@/assets/image/not-found.jpg')"
                :alt="product.good.name" />
              <div class="cart__info">
                <h3 class="cart__item-name">
                  {{ product.good.name}}
                </h3>
                <p class="cart__item-decr">{{product.good.description}}</p>
                <div class="cart__item-delivery">
                  {{product.good.delivery_date}}
                </div>
                <div class="cart__item-rating"><StarRating :rating="product.good.rating"/></div>
                <div class="cart__item-shield">
                <span
                  class="cart__item-shield--orange"
                  v-if="product.good.discount > 0"
                >
                  -{{product.good.discount}}%
                </span>
                  <span v-if="product.good.is_hit" class="cart__item-shield--green">ХИТ</span>
                </div>
              </div>
            </div>
            <div class="cart__item--mobile">
              <div class="cart__item-shield cart__item-shield--mobile">
                <span
                  class="cart__item-shield--orange"
                  v-if="product.good.discount > 0"
                >
                  -{{product.good.discount}}%
                </span>
                <span class="cart__item-shield--green">ХИТ</span>
              </div>
              <div class="cart__item-rating cart__item-rating--mobile">
                <StarRating :rating="product.good.rating"/>
              </div>
            </div>

            <!------------------>
            <div class="cart__item-right">
              <div class="cart__price price">
                <div class="price__one">
                  <span class="price__new">{{ formatPrice(product.good.price) }}тг</span>
                  <span
                    class="price__old"
                    v-if="product.good.discount > 0"
                  >
                  {{ priceBeforeDiscount(product.good.price, product.good.discount) }} тг
                </span>
                </div>
                <div class="price__counter">
                  <div class="price__counter-wrapper">
                    <button
                      class="price__inc"
                      @click="reduceProductInCart(product)"
                    >
                      -
                    </button>
                    <span class="price__quantity">{{ product.quantity}}</span>
                    <button
                      class="price__decr"
                      @click="ADD_PRODUCT_TO_CART({product: product.good})" >
                      +
                    </button>
                  </div>
                  <span>По 1 шт.</span>
                </div>
                <span class="price__total">{{ formatPrice(product.quantity * product.good.price) }}</span>
              </div>
              <button class="cart__delete" @click="DELETE_FROM_CART(product.good.id)">
                <svg width="11" height="13" viewBox="0 0 14 17" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.97222 0H4.25L3.30556 0.944444H0V2.83333H13.2222V0.944444H9.91667L8.97222
              0ZM10.3889 5.66667V15.1111H2.83333V5.66667H10.3889ZM0.944336 3.77783H12.2777V15.1112C12.2777 16.1501 11.4277 17.0001 10.3888
              17.0001H2.83322C1.79434 17.0001 0.944336 16.1501 0.944336 15.1112V3.77783Z"/>
                </svg>
              </button>
            </div>
          </div>
          <!--ITEM-->
        </div>
        <div class="cart__promocode promocode">
          <div class="promocode__wrapper">
            <div class="promocode__info">
              <div class="promocode__icon">
                <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9294 10.9676C17.5554 11.6496 16.8294 12.1006 16.0045 12.1006H7.80974L6.59978 14.3005H19.7993V16.5004H6.59978C4.92783 16.5004 3.87187 14.7075 4.67484 13.2335L6.15979 10.5496L2.19993 2.2009H0V0.000976562H3.59688L4.63084 2.2009H20.9103C21.7463 2.2009 22.2742 3.10287 21.8673 3.82885L17.9294 10.9676ZM19.0403 4.40083H5.67571L8.28262 9.90064H16.0044L19.0403 4.40083ZM6.59981 17.6006C5.38986 17.6006 4.41089 18.5906 4.41089 19.8005C4.41089 21.0105 5.38986 22.0004 6.59981 22.0004C7.80977 22.0004 8.79974 21.0105 8.79974 19.8005C8.79974 18.5906 7.80977 17.6006 6.59981 17.6006ZM15.4104 19.8005C15.4104 18.5906 16.3894 17.6006 17.5993 17.6006C18.8093 17.6006 19.7993 18.5906 19.7993 19.8005C19.7993 21.0105 18.8093 22.0004 17.5993 22.0004C16.3894 22.0004 15.4104 21.0105 15.4104 19.8005Z" fill="#333333"/>
                </svg>
              </div>
              <p class="promocode__text">
                Вы набрали необходимую сумму для бесплатной доставки до пункта выдачи
                заказа. Подберите удобный ПВЗ на странице оформления заказа.
              </p>
            </div>
            <div class="promocode__field">
              <div class="promocode__wrap">
                <input
                  class="promocode__input"
                  type="text"
                  placeholder="Промокод"
                  v-model="enteredPromocode"
                  @keyup.enter="sendPromocode"
                  @change="dataPromocode = {}"
                >
                <div class="promocode__message">{{messagePromocode}}</div>
              </div>
              <button class="promocode__btn" @click="sendPromocode" >Применить</button>
            </div>
            <p class="promocode__discount-info">
              При сумме заказа выше 60.000, Вам предоставляется скидка в <span>размере 15%.</span>
            </p>
            <div class="promocode__without-discount" v-if="isTotalPriceWithoutDiscount > 51000 || dataPromocodeSaleSum > 0">
              <div class="promocode__previously">
                <span>Итого:</span>
                <span>{{ formatPrice(isTotalPriceWithoutDiscount) }} тг</span>
              </div>
              <div v-if="isTotalPriceWithoutDiscount > 51000" class="promocode__discount">
                <span>Скидка:</span>
                <span>15%</span>
              </div>
            </div>
            <div class="promocode__total-price">
              <span>Всего:</span>
              <span>{{ formatPrice(isTotalPrice) }} тг</span>
            </div>
            <div v-if="this.profileData.wholesaleCustomer && totalPrice < 51000" class="promocode__btn-group">
              <span class="promocode__discount-info">Минимальная сумма заказа для оптовых покупателей от 60000 тг</span>
            </div>
            <div class="promocode__btn-group">
              <router-link v-if="!this.profileData.wholesaleCustomer || totalPrice > 51000" :to="{name: 'order', params: {promocode: enteredPromocode}}" class="promocode__btn-send">Оформить заказ</router-link>
              <button class="promocode__btn-clear" @click="deleteAllFromCart">Очистить заказ</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SimilarProductsBlock class="cart__similar" />
    <SimilarProductsBlock
      class="cart__similar--mobile"
      :isVisibleButtonProps="true"
      :limitProductsProps="2"
    />
  </section>
</template>

<script>
import StarRating from '../common/StarRating'
import { mapGetters, mapActions } from 'vuex'
import { fetchWithAuth, api } from '@/api/api'
import formatPrice from '@/mixins/formatPrice'
import SimilarProductsBlock from '@/components/common/SimilarProductsBlock'

export default {
  name: 'VCart',
  components: {
    SimilarProductsBlock,
    StarRating,
  },
  mixins: [formatPrice],
  data() {
    return {
      checkedProducts: [],
      isCheckedAll: false,
      enteredPromocode: '',
      dataPromocode: {},
      dataPromocodeSaleSum: 0,
    }
  },
  computed: {
    ...mapGetters([
      'cart',
      'totalPrice',
      'profileData',
      'orderWithAuth',
    ]),
    messagePromocode() {
      if (this.dataPromocode.error) {
        return 'Промокод не применен'
      }
      else if  (this.dataPromocode["Скидка"] === undefined) {
        return ''
      } else {
        return this.dataPromocode["Скидка"] === 0 ? "Промокод недействителен" : `Скидка по промокоду ${this.dataPromocode["Скидка"]} тг`
      }
    },
    isTotalPrice() {
      if (this.dataPromocodeSaleSum > 0) {
        if(!localStorage.getItem('access_token')) {
          const priceSum =  this.formatPrice(this.totalPrice > 60000 ? Math.floor((this.totalPrice * 0.85) - this.dataPromocodeSaleSum) : this.totalPrice - this.dataPromocodeSaleSum);
          this.$store.commit('SET_TOTAL_PRICE_WITH_PROMOCODE', priceSum)
          return priceSum
        } else {
          this.$store.commit('SET_TOTAL_PRICE_WITH_PROMOCODE', this.formatPrice(this.totalPrice - this.dataPromocodeSaleSum) )
          return this.formatPrice(this.totalPrice - this.dataPromocodeSaleSum);
        }
      } else {
        if(localStorage.getItem('access_token')) {
          this.$store.commit('SET_TOTAL_PRICE_WITH_PROMOCODE', this.totalPrice > 60000 ? Math.floor(this.totalPrice * 0.85) : this.totalPrice )
          return this.formatPrice(this.totalPrice > 60000 ? Math.floor(this.totalPrice * 0.85) : this.totalPrice)
        } else {
          this.$store.commit('SET_TOTAL_PRICE_WITH_PROMOCODE', this.totalPrice > 60000 ? Math.floor(this.totalPrice * 0.85) : this.totalPrice )
          return this.formatPrice(this.totalPrice > 60000 ? Math.floor(this.totalPrice * 0.85) : this.totalPrice)
        }
      }
    },
    isTotalPriceWithoutDiscount() {
      let priceWithoutDiscount = 0;
      this.cart.forEach(product => priceWithoutDiscount += (product.good.price * product.quantity));
      return priceWithoutDiscount;
    },
  },
  methods: {
    ...mapActions([
      'GET_PRODUCTS_CART',
      'GET_TOTAL_PRICE',
      'ADD_TO_NOTIFICATION',
      'ADD_PRODUCT_TO_CART',
      'DELETE_FROM_CART',
      'REDUCE_PRODUCT_IN_CART',
      'GET_FAVORITE_PRODUCTS',
      'DELETE_ALL_FROM_CART',
      'GET_PROFILE_DATA',
    ]),
    getProfileData() {
      if(this.orderWithAuth) {
        this.GET_PROFILE_DATA();
      }
    },
    reduceProductInCart(product) {
      if (product.quantity > 1) {
        this.REDUCE_PRODUCT_IN_CART(product.good.id)
      }
    },
    priceBeforeDiscount(price, discount) {
      const priceWithoutDiscount = Math.ceil((price * 100) / (100 - discount));
      return this.formatPrice(priceWithoutDiscount);
    },
    checkedAll() {
      this.isCheckedAll = !this.isCheckedAll;
      if (this.isCheckedAll) {
        const allCheckbox = this.$refs.isCheckProducts;
        allCheckbox.forEach(el => {
          this.checkedProducts.push(Number(el.id))
        })
      } else this.checkedProducts = [];
    },
    updateCheckAll() {
      if (this.$refs.isCheckProducts !== this.checkedProducts) {
        this.isCheckedAll = false;
      } else {
        this.isCheckedAll = true;
      }
    },
    addToFavorites() {
      if (this.checkedProducts.length) {
        this.checkedProducts.forEach(productID => {
          fetchWithAuth.post(`api/catalogue/${productID}/add-to-favorites`)
            .then(response => {
              this.ADD_TO_NOTIFICATION({message: 'Выбранные товары добавлены в избранное'})
              this.GET_FAVORITE_PRODUCTS();
              this.checkedProducts = [];
            })
            .catch(error => {
            })
        })
      }
    },
    deleteSelectedProducts() {
      if (this.checkedProducts.length) {
        this.checkedProducts.forEach(productID => {
          this.DELETE_FROM_CART(productID);
        })
        this.checkedProducts = [];
        this.ADD_TO_NOTIFICATION({message: 'Выбранные товары удалены'});
        this.GET_PRODUCTS_CART();
      }
    },
    sendPromocode() {
      if (localStorage.getItem('access_token')) {
        fetchWithAuth.get(`api/cart/get-total/apply-promocode/${this.enteredPromocode}`)
          .then(res => {
            this.dataPromocode = res.data;
            this.dataPromocodeSaleSum = res.data['Скидка']
          })
          .catch(error => {
            this.dataPromocode = {error: 'error'}
          })
      } else {
        let order_items = [];
        this.cart.forEach(el => order_items.push({id: el.good.id, unit_quantity: el.quantity, cost: el.good.price}))
        api.post('api/guest-order/apply-promocode', {
          order_items: order_items,
          promocode: this.enteredPromocode,
        })
          .then(res => {
            this.dataPromocode = res.data;
            this.dataPromocodeSaleSum = res.data['Скидка']
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    discountAfterLimit(price, limit = 60000) {
      return this.formatPrice(price > limit ? Math.ceil(price * 0.85) : price)
    },
    deleteAllFromCart() {
      if (this.cart.length) {
        this.DELETE_ALL_FROM_CART()
      }
    }
  },
  created() {
    this.GET_PRODUCTS_CART()
    this.GET_TOTAL_PRICE();
    this.getProfileData();
  },
  watch: {
    totalPrice() {
      this.dataPromocodeSaleSum = 0;
      this.dataPromocode = {};
      this.enteredPromocode = '';
    },
  },
}
</script>

<style scoped lang="scss">
.checkmark {
  display: flex;
}
.checkbox-hidden {
  width: 0;
  height: 0;
  opacity: 0;
}
.checkbox-fake {
  position: relative;
  display: block;
  width: 16px;

  height: 16px;
  border: 1px solid white;
}
.label-fake {
  margin-left: 1em;
}
.checkbox-hidden:checked ~ .checkbox-fake {
  opacity: 1;
}
.checkbox-fake::after {
  content: ' \2713';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
.checkbox-hidden:checked ~ .checkbox-fake::after {
  opacity: 1;
}

.cart {
  &__wrapper {
    margin-bottom: 60px;
  }
  &__container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 0 15px;
  }
  &__items{
    margin-bottom: 60px;
    @include phone {
      margin-bottom: 20px;
    }
  }
  &__item {
    display: flex;
    justify-content: space-between;
    min-height: 264px;
    @include medium-tablet {
      flex-direction: column;
    }
  }
  &__title {
    margin-bottom: 49px;
    font-size: 36px;
    font-weight: 500;
    & span {
      color: $orange-color;
    }
  }
  &__options {
    display: flex;
    justify-content: space-between;
    padding-bottom: 29px;
    font-size: 16px;
    font-weight: 400;
    @include tablet {
      flex-direction: column;
    }
  }
  &__all {
    display: flex;
    align-items: center;
    @include phone {
      justify-content: space-between;
      margin-bottom: 15px;
    }
    &-count {
      margin-left: 43px;
      @include phone {
        margin-left: 0;
      }
    }
  }
  &__move-delete {
    display: flex;
    & svg {
      margin-right: 14px;
    }
  }
  &__move-favorite {
    display: flex;
    margin-right: 70px;
    @include phone {
      margin-bottom: 15px;
    }
    & svg {
      margin-right: 14px;
    }
  }

  &__checkmark {
    display: flex;
    align-items: center;
    margin-right: 43px;
  }
  &__checkbox-fake {
    margin-right: 16px;
  }

  &__move {
    display: flex;
    color: white;
    font-size: 16px;
    @include phone {
      flex-direction: column;
    }
    & button {
      color: white;
      font-size: 16px;
    }
  }
  &__item {
    display: flex;
    padding: 30px 0;
    border-bottom: 2px solid $grey-line;
    @include phone {
      flex-direction: column;
      padding: 20px 0;
    }
    &-left {
      display: flex;
    }
    &-right {
      display: flex;
      @include phone {
        justify-content: space-between;
      }
    }
  }

  &__info {
    max-width: 322px;
    @include phone {
      width: auto;
    }
  }

  &__item-name {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 7px;
  }

  &__item-decr {
    color: $grey-text;
    margin-bottom: 1.5em;
  }

  &__item-delivery {
    font-weight: 500;
    margin-bottom: 20px;
    @include phone {
      margin-bottom: 0;
      text-align: end;
    }
  }

  &__options {
    border-bottom: 2px solid $grey-line;
  }

  &__checkbox {
    margin-right: 20px;
  }

  &__img {
    width: 125px;
    height: 125px;
    margin: 0 23px;
    @include phone {
      min-width: 100px;
      max-width: 101px;
      height: 100px;
      margin: 0 10px;
    }
  }

  &__delete {
    margin-right: 5px;
    margin-left: 20px;
    & svg {
      fill: #DADADA;
    }
  }

  &__item-rating {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    font-weight: 500;
    @include phone {
      display: none;
    }
  }
  &__item--mobile {
    display: none;
    @include phone {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      margin-left: 30px;
    }
  }
  &__item-shield--mobile {
    display: none;
    @include phone {
      display: flex;
    }
  }
  &__item-rating--mobile {
    display: none;
    align-items: center;
    margin-bottom: 18px;
    font-weight: 500;
    @include phone {
      display: flex;
    }
  }

  &__item-shield {
    @include phone {
      display: none;
    }
    & span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 5px 7px;
      font-size: 10px;
      font-weight: 500;
      line-height: 13px;
      border-radius: 6px;
    }
    &--green {
      background-color: #43BC76;
    }

    &--orange {
      color: $main-bg;
      background-color: $orange-color;
      margin-right: 10px;
    }
  }
  &__item-shield--mobile {
    display: none;
    @include phone {
      display: flex;
      margin-bottom: 15px;
    }
  }
  &__delete {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  &__similar {
    @include phone {
      display: none;
    }
  }
  &__similar--mobile {
    display: none;
    @include phone {
      display: flex;
      margin-top: 40px;
    }
  }
}
.price {
  display: inline-flex;
  width: 372px;
  @include phone {
    width: auto;
  }
  &__counter-wrapper {
    display: flex;
    flex-wrap: nowrap;
    color: #2E2E2E;
    & button {
      width: 43px;
      height: 43px;
      background-color: #EFEFEF;
      padding: 7px 10px;
      border-radius: 50%;
      cursor: pointer;
      @include phone {
        width: 30px;
        height: 30px;
      }
    }
  }
  &__counter {
    text-align: center;
    margin: 0 24px;
    @include phone {
      margin: 0 10px;
    }
  }
  &__quantity {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 60px;
    height: 43px;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
    border-radius: 100px;
    @include phone {
      width: 50px;
      height: 30px;
    }
  }
  &__one {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    font-size: 18px;
    @include phone {
      font-size: 16px;
    }
  }
  &__total {
    font-size: 18px;
    @include phone {
      font-size: 16px;
    }
  }
  &__old {
    font-size: 10px;
    color: #8E8E8E;
    text-decoration: line-through;
    text-align: end;
  }
}

.promocode {
  margin-left: auto;
  margin-right: 0;
  width: 52%;
  @include phone {
    width: 100%;
  }
  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid $light-gray;
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    padding: 19px;
    margin-right: 29px;
    background-color: $orange-color;
    border-radius: 50%;
  }
  &__text {
    color: $grey-text;
  }
  &__wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__field {
    display: flex;
    margin-bottom: 5px;
    @include phone {
      display: flex;
      flex-direction: column;
    }
  }
  &__input {
    width: 100%;
    height: 50px;
    font-weight: 500;
    color: $dark-grey;
    background-color: $main-bg;
    border: 2px solid $dark-grey;
    border-radius: 5px;
    @include phone {
      width: 100%;
      margin-bottom: 10px;
      height: 44px;
      font-size: 16px;
    }
  }
  &__btn {
    height: 50px;
    padding: 16px 24px;
    margin-left: 16px;
    background-color: $orange-color;
    border-radius: 5px;
    @include phone {
      width: 100%;
      margin-left: 0;
      height: 44px;
      font-size: 16px;
    }
  }
  &__message {
    margin-top: 7px;
    color: $dark-grey;
    @include phone {
      margin-top: 0;
      margin-bottom: 7px;
    }
  }
  &__without-discount {
    margin-bottom: 12px;
    font-weight: 500;
  }
  &__previously {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: 14px;
    & span {
      font-weight: 700;
    }
  }
  &__discount {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 700;
    color: $orange-color;
  }
  &__discount-info {
    margin-top: 24px;
    margin-bottom: 12px;
    & > span {
      color: $orange-color;
    }
    @include phone {
      font-size: 14px;
      text-align: center;
    }
  }
  &__total-price {
    display: flex;
    justify-content: space-between;
    padding-bottom: 26px;
    margin-bottom: 24px;
    font-size: 18px;
    font-weight: 500;
    border-bottom: 1px solid $light-gray;

  }
  &__btn-group {
    display: flex;
    justify-content: end;
    @include phone {
      display: flex;
      flex-direction: column;
    }
  }
  &__btn-send {
    display: inline-block;
    height: 50px;
    padding: 16px 24px;
    color: $main-bg;
    background-color: $orange-color;
    border-radius: 5px;
    @include phone {
      width: 100%;
      text-align: center;
      margin-bottom: 15px;
    }

  }
  &__btn-clear {
    height: 50px;
    padding: 16px 24px;
    margin-left: 16px;
    color: $dark-grey;
    background-color: $main-bg;
    border: 2px solid $dark-grey;
    border-radius: 5px;
    @include phone {
      width: 100%;
      margin-left: 0;
    }
  }
}

</style>

