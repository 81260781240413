<template>
  <div v-show="showLoginModal" ref="loginModal" class="form__overlay" @click.stop="closeFrom">
    <div class="form__wrapper">
      <form  v-show="isFormDefaultVisible" class="login__form form"  @submit.prevent="formSubmit">
        <button class="form__close">
          <img src="@/assets/image/sidebarIcons/hide-sidebar.svg" ref="closeForm" @click.stop="closeFrom">
        </button>
        <h3 class="form__title">Войти</h3>
        <label class="form__label-email" for="email-input">Электронная почта</label>
        <input
          class="form__input"
          id="email-input"
          type="email"
          v-model.trim="formData.email"
          placeholder="Почта"
          @change="responseErrorMessage= ''"
          autocomplete="username"
        />
        <p
          v-if="$v.formData.email.$dirty && !$v.formData.email.required"
          class="form__feedback--required"
        >Обязательное поле
        </p>
        <p
          v-if="$v.formData.email.$dirty && !$v.formData.email.email"
          class="form__feedback"
        >Почта введена некорректно
        </p>
        <div class="form__group-line">
          <label class="form__label" for="password-input">Пароль</label>
          <button class="form__link" @click="clickForgotPassword">Забыли пароль?</button>
        </div>

        <div class="form__wrap">
          <input
            v-show="!showPass"
            class="form__input"
            id="password-input"
            type="password"
            v-model.trim="formData.password"
            placeholder="Пароль"
            @change="responseErrorMessage= ''"
            autocomplete="current-password"
          />
          <input
            v-show="showPass"
            class="form__input"
            type="text"
            v-model.trim="formData.password"
            placeholder="Пароль"
            @change="responseErrorMessage= ''"
            autocomplete="current-password"
          />
          <img
            class="form__show-password"
            src="@/assets/image/icons/show-password.svg"
            @click="showPassword($event,'showPass')"
          >
        </div>
        <p
          v-if="$v.formData.password.$dirty && !$v.formData.password.required"
          class="form__feedback--required"
        > Обязательное поле
        </p>
        <p
          v-if="$v.formData.password.$dirty && !$v.formData.password.minLength"
          class="form__feedback"
        >Не менее 8 символов
        </p>
        <span class="form__error">{{ responseErrorMessage }}</span>
        <div class="form__group-line">
          <button class="form__button">Войти</button>
          <span>или</span>
          <router-link class="form__link" to="/register">Регистрация</router-link>
        </div>
      </form>
      <form v-show="isFormForgotPasswordVisible" ref="formForgot" class="login__form form"  @submit.prevent="formForgotSubmit">
        <button class="form__close">
          <img src="@/assets/image/sidebarIcons/hide-sidebar.svg" ref="closeFormForgotPassword" @click.stop="closeFrom">
        </button>
        <h3 class="form__title">Восстановить пароль</h3>
        <p class="form__info">Пожалуйста, введите ваш адрес электронной почты, на который мы отправим ссылку для восстановления пароля</p>
        <label class="form__label-email" for="email-input">Электронная почта</label>
        <input
          class="form__input"
          type="email"
          v-model.trim="formDataForRecovery.email"
          placeholder="Почта"
          @input="responseErrorMessageFormForgot= ''"
          autocomplete="username"
        />
        <p
          v-if="$v.formDataForRecovery.email.$dirty && !$v.formDataForRecovery.email.required"
          class="form__feedback--required"
        >Обязательное поле
        </p>
        <p
          v-if="$v.formDataForRecovery.email.$dirty && !$v.formDataForRecovery.email.email"
          class="form__feedback"
        >Почта введена некорректно
        </p>
        <span class="form__error" v-show="isFormDefaultVisible">{{ responseErrorMessage }}</span>
        <span class="form__error" v-show="isFormForgotPasswordVisible">{{ responseErrorMessageFormForgot }}</span>
        <span class="form__response">{{ responseMessage }}</span>
        <div class="form__group-line">
          <button class="form__button">Отправить</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {api} from '@/api/api'
import { validationMixin } from 'vuelidate';
import { required, minLength, email } from 'vuelidate/lib/validators';
import {mapMutations, mapGetters, mapActions} from 'vuex';
import showPassword from "@/mixins/showPassword";

export default {
  name: 'LoginModal',
  mixins: [validationMixin, showPassword],
  data() {
    return {
      formData: {
        email: '',
        password: '',
      },
      formDataForRecovery: {
        email: ''
      },
      responseMessage: '',
      responseErrorMessage: '',
      responseErrorMessageFormForgot: '',
      isFormForgotPasswordVisible: false,
      isFormDefaultVisible: true,
      showPass: false,
    }
  },
  computed: {
    ...mapGetters([
      'showLoginModal'
    ]),
  },
  methods: {
    ...mapMutations([
      'HIDE_FORM_LOGIN',
    ]),
    formSubmit() {
      this.$v.formData.$touch();
      if (!this.$v.formData.$error) {
        api.post(`api/auth/login`, this.formData)
          .then((response) => {
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('refresh_token', response.data.refresh_token)
            this.$store.commit('CHANGE_AUTH_STATUS', true)
            this.$store.commit('HIDE_FORM_LOGIN')
          })
          .catch((error) => {
            if(error.response.status === 401) {
              this.responseErrorMessage = 'Неверно введены пароль или почта';
            }

          })
      }
    },
    formForgotSubmit() {
      this.$v.formDataForRecovery.$touch();
      if (!this.$v.formDataForRecovery.$error) {
        api.post(`api/auth/forgot-password`, this.formDataForRecovery)
          .then((response) => {
            this.responseMessage = response.data.message;
          })
          .catch((error) => {
            if(error.response.status === 422) {
              this.responseErrorMessageFormForgot = 'Аккаунта с указанной почтой не найдено';
            }
          })
      }
    },
    closeFrom(e) {
      if (e.target === this.$refs.closeFormForgotPassword) {
        this.isFormForgotPasswordVisible = false;
        this.isFormDefaultVisible = true;
      }
      if(e.target === this.$refs.loginModal || e.target === this.$refs.closeForm) {
        this.$store.commit('HIDE_FORM_LOGIN')
        this.formData = {
          email: '',
          password: '',
        };
        this.formDataForRecovery = {
          email: '',
        };
        this.responseMessage=  '';
        this.responseErrorMessage = '';
        this.responseErrorMessageFormForgot = '';
        this.isFormForgotPasswordVisible = false;
        this.isFormDefaultVisible = true;
        this.showPass = false;
      }
    },
    clickForgotPassword() {
      this.isFormForgotPasswordVisible = true;
      this.isFormDefaultVisible = false;
    },
  },
  validations: {
    formData: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
      },
    },
    formDataForRecovery: {
      email: {
        required,
        email,
      },
    },
  },
}
</script>

<style scoped lang="scss">
.form {
  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    //height: 100%;
    //width: 100%;
    background-color: rgba(51, 51, 51, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    width: 430px;
    padding: 50px;
    border-radius: 10px;
    background-color: $main-dark;
    position: relative;
  }
  &__close {
    position: absolute;
    left: 92%;
    z-index: 10001;
    & > img {
      width: 16px;
      height: 17px;
    }
  }
  &__error {
    font-size: 12px;
    color: #e32526;
  }
  &__response {
    font-size: 12px;
    color: #43BC76;
  }
  &__wrap {
    position: relative;
  }
  &__show-password.active {
    &::after {
      opacity: 0;
    }
  }
  &__show-password {
    position: absolute;
    display: block;
    width: 24px;
    height: 24px;
    top: 18px;
    right: 25px;
    &::after {
      position: absolute;
      z-index: 100;
      content: '';
      top: 10px;
      right: 0;
      width: 25px;
      height: 2px;
      background-color: $grey-line;
      transform: rotate(45deg);
      opacity: 1;
    }
  }
}
.form {
  position: relative;
  z-index: 200;
  background-color: $main-dark;
  display: flex;
  flex-direction: column;
  &__input {
    width: 330px;
    height: 50px;
    background-color: $main-bg;
    padding-left: 10px;
    border-radius: 5px;
    color: white;
    margin-bottom: 10px;
  }
  &__input-email {
    margin-bottom: 20px;
  }
  &__feedback {
    font-size: 12px;
    color: #e32526;
  }
  &__feedback--required {
    font-size: 12px;
    color: $grey-text;
  }
  &__label {
    font-size: 15px;
    line-height: 20px;
  }
  &__info {
    color: $grey-line;
    margin-bottom: 20px;
  }
  &__label-email {
    margin-bottom: 15px;
  }
  &__title {
    font-weight: 400;
    font-size: 28px;
    line-height: 22px;
    margin-bottom: 20px;
  }
  &__button {
    width: 178px;
    padding: 14px 40px;
    background-color: $orange-color;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #fc9c4c;
    }
  }
  &__group-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 15px;
    line-height: 20px;
  }
  &__link {
    display: inline-block;
    text-decoration: underline;
    padding-right: 10px;
    color: white;
  }
  &__checkbox {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    height: 24px;
    margin-right: 14px;
    background-color: white;
    border-radius: 6px;
    cursor: pointer;

    &:after {
      content: '\2714';
      font-weight: bold;
      display: none;
    }

    &:checked {
      &:after {
        display: inline;
      }
    }
  }
}


</style>
