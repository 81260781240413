<template>
  <section class="interests">
    <div class="container interests__container">
      <h2 class="interests__title">Свечи мастера</h2>
      <div class="interests__list">
        <div
          v-for="popular in categories.slice(0,6)"
          :key="popular.id"
          class="interests__card"
          @click="$router.push({path: `/catalogue/categories/${popular.id}`})"
        >
          <span class="interests__name">{{ popular.name }}</span>
          <img class="interests__image" :src="popular.img" :alt="popular.name">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'VCategories',
  computed: {
    ...mapGetters([
      'categories'
    ])
  },
  methods: {
    ...mapActions([
      'GET_CATEGORIES',
    ])
  },
  mounted() {
    this.GET_CATEGORIES();
  }
}
</script>

<style scoped lang="scss">
.interests {
  width: 100%;
  background: url('/src/assets/image/info.png') center / cover no-repeat;
  margin-top: 120px;
  padding-bottom: 60px;
  border-radius: 5px;
  @include phone {
    margin-top: 30px;
  }
  &__container {
    padding: 0 15px;
  }
  &__title {
    font-size: 2.5em;
    font-weight: 500;
    margin-bottom: 36px;
    padding-top: 60px;
    @include tablet {
      margin-left: 10px;
    }
    @include phone {
      margin-left: 10px;
      font-size: 24px;
      padding-top: 30px;
    }
  }
  &__image {
    height: 100%;
    max-width: 100px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  &__wrapper{
    display: flex;
    flex-direction: column;
  }
  &__card {
    display: flex;
    justify-content: space-around;
    cursor: pointer;
    align-items: center;
    width: 360px;
    height: 100px;
    background-color: $main-bg;
    border-radius: 10px;
    margin-bottom: 1.4em;
    overflow: hidden;
    margin-right: 10px;
    @include phone {
      margin-right: 0px;
    }
  }

  &__name {
    font-size: 24px;
    font-weight: bold;
    margin-left: 24px;
    //white-space: nowrap;
    @include phone {
      margin-left: 2em;
      font-size: 18px;
      font-weight: 500;
    }
  }
                                                                                                                                                                                                                                                                                                                                                                    }
</style>
