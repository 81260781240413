<template>
  <div class="catalog">
    <div class="container catalog__container">
      <div class="catalog__menu">
        <h2 class="catalog__title">
          <router-link to="/catalogue">
            Каталог
          </router-link>
        </h2>
        <div class="catalog__options">
          <VInput class="catalog__options-item"/>
          <VSelect @sort="sortByPrice" />
        </div>
      </div>
      <div class="catalog__list">
        <VCatalogItem
          v-for="product in sortedProducts.slice(0,8) || allProducts.slice(0,8)"
          :key="product.id"
          :product_data="product"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VCatalogItem from './VCatalogItem'
import VSelect from '../common/VSelect'
import VInput from '../common/VInput'
import { mapGetters } from 'vuex'

export default {
  name: 'VCatalog',
  components: {
    VCatalogItem,
    VSelect,
    VInput,
  },
  data() {
    return {
      sortedProducts : '',
    }
  },
  computed: {
    ...mapGetters([
      'allProducts'
    ])
  },
  methods: {
    sortByPrice(sort) {
      if (sort === 'default') {
        this.sortedProducts = [...this.allProducts];
      }
      if (sort === 'asc') {
        this.sortedProducts = this.allProducts.slice().sort((a, b) => {
          return (a.price < b.price) ? -1 : (a.price > b.price) ? 1 : 0
        });
      }
      if (sort === 'decr') {
        this.sortedProducts = this.allProducts.slice().sort((a, b) => {
          return (a.price < b.price) ? 1 : (a.price > b.price) ? -1 : 0
        });
      }
      this.pageNumber = 1;
    },
  },
}
</script>

<style scoped lang="scss">
.catalog {
  margin-top: 120px;
  @include phone {
    margin-top: 20px;
  }
  &__container {
    padding: 0 15px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include tablet {
      justify-content: space-around;
    }
  }
  &__title {
    font-size: 2.5em;
    font-weight: 400;
    margin-bottom: 36px;
    @include phone {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 20px;
      @include phone {
        margin-bottom: 15px;
      }
    }
  }
  &__menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include phone {
      align-items: stretch;
      flex-direction: column;
    }
  }
  &__options {
    display: flex;
    @include phone {
      flex-direction: column;
      margin-bottom: 20px;
    }
    &-item {
      margin-right: 24px;
      @include phone {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
