import { fetchWithAuth } from '@/api/api';
import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'cart',
      'notification'
    ]),
  },
  methods: {
    ...mapActions([
      'ADD_TO_NOTIFICATION',
    ]),
    ...mapMutations([
      'HIDE_FEEDBACK_MODAL'
    ]),
    addFeedback(data) {
        return fetchWithAuth.post(`api/ask-a-question`, data)
          .then(res => {
            this.HIDE_FEEDBACK_MODAL()
            this.ADD_TO_NOTIFICATION({message: "Запрос отправлен успешно"});
          })
          .catch(error => {
            this.ADD_TO_NOTIFICATION({message: 'К сожалению, произошла ошибка'})
          })
    }
  }
}
