<template>
  <div class="product-slider">
    <div ref="productSwiper1" class="swiper-container gallery-top">
      <div class="swiper-wrapper">
        <div class="swiper-slide gallery-top__slide">
            <img class="swiper-slide-img" :src="productImages && productImages[0] ? productImages[0] : require('/src/assets/image/not-found.jpg')" alt="">
        </div>
        <div class="swiper-slide gallery-top__slide">
          <img
            v-if="productImages && productImages[1]"
            class="swiper-slide-img"
           :src="productImages[1]">
        </div>
        <div class="swiper-slide gallery-top__slide">
            <img
              v-if="productImages  && productImages[2]"
              class="swiper-slide-img"
              :src="productImages[2]"
            >
        </div>
      </div>
      <div class="swiper-button-next button__next" style="z-index: 1">
        <button class="product-slider__button">
          <svg class="product-slider__svg" width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.96432 10.0398L7.85243 5.49925L0.96432 0.958694"/>
          </svg>
        </button>
      </div>
      <div class="swiper-button-prev button__prev" style="z-index: 1">
        <button class="product-slider__button">
          <svg class="product-slider__svg" width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.55374 8.56814L0.619368 4.49933L6.55374 0.430515"/>
          </svg>
        </button>
      </div>
<!--      <div class="product-slider__shield" v-show="isAction">Акция</div>-->
    </div>
    <div  ref="productSwiper2" class="swiper-container gallery-thumbs">
      <div class="swiper-wrapper">
        <div class="swiper-slide gallery-thumbs__slide">
          <img class="swiper-slide-img" :src="productImages && productImages[0] ?  productImages[0] : require('/src/assets/image/not-found.jpg')" alt="">
        </div>
        <div class="swiper-slide gallery-thumbs__slide">
          <img
            v-if="productImages && productImages[1]"
            class="swiper-slide-img"
            :src="productImages[1]">
        </div>
        <div class="swiper-slide gallery-thumbs__slide">
          <img
            v-if="productImages && productImages[2]"
            class="swiper-slide-img"
            :src="productImages[2]"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper, { Navigation, Pagination, Controller } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import {api} from "@/api/api";

export default {
  name: 'ProductSlider',
  components: {
  },
  props: {
    productImages: {
      type: Array,
      default() {
        return []
      }
    },
    isAction: {
      type: Boolean,
      default() {
        return false
      }
    },
  },
  methods: {
    slider() {
      let galleryThumbs = new Swiper(this.$refs.productSwiper2, {
        modules: [Navigation, Pagination, Controller],
        spaceBetween: 10,
        slidesPerView: 3,
        freeMode: true,
        watchSlidesProgress: true,
      });

      let galleryTop = new Swiper(this.$refs.productSwiper1, {
        modules: [Navigation, Pagination, Controller],
        slidesPerView: 1,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    }
  },
  mounted() {
    this.slider();
  },

  updated() {
    this.slider()
  }
}
</script>

<style scoped lang="scss">
.product-slider {
  width: 550px;
  @include small-desktop {
    width: 400px;
  }
  @include medium-tablet {
    width: 300px;
  }
  @include phone {
    width: 178px;
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin: 0 -30px;
    background-color: #F4F3F6;
    border-radius: 50%;
    @include phone {
      display: none;
    }
    &:active {
      background-color: $orange-color;
      & .product-slider__svg {
        fill: black;
      }
    }
  }
  &__svg {
    fill: #C4C4C4;
  }
  &__shield {
    position: absolute;
    top: 20px;
    right: 24px;
    z-index: 99;
    background-color: #6FAC54;
    border-radius: 10px;
    padding: 11px 30px;
  }
}
.gallery-top {
  position: relative;
  overflow: hidden;
}
.gallery-thumbs {
  padding: 10px 0;
  overflow: hidden;
}
.gallery-top__slide {
  width: 550px;
  height: 550px;
  overflow: hidden;
  @include small-desktop {
    width: 400px;
    height: 400px;
  }
  @include medium-tablet {
    width: 300px;
    height: 300px;
  }
  @include phone {
    width: 178px;
    height: 178px;
  }
}
.gallery-thumbs__slide {
  width: 176px;
  height: 147px;
  opacity: 0.4;
  margin-right: 10px;
  overflow: hidden;
  &:last-child {
    margin-right: 0;
  }
  @include phone {
    width: 55px;
    height: 50px;
  }
}
.gallery-thumbs .swiper-slide-active {
  opacity: 1;
}
.swiper-slide-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  @include phone {
    border-radius: 5px;
  }
}
.button__prev {
  top: 45%;
  margin: 0 30px;
  @include tablet {
    margin: 0 10px;
  }
}
.button__next {
  top: 45%;
  margin: 0 30px;
  @include tablet {
    margin: 0 10px;
  }
}
.swiper-button-prev::after {
  display: none;
}
.swiper-button-next::after {
  display: none;
}
</style>


