import Vue from 'vue'
import Vuex from 'vuex'
import catalogue from '@/store/modules/catalogue'
import cart from '@/store/modules/cart'
import categories from '@/store/modules/categories'
import auth from '@/store/modules/auth'
import search from '@/store/modules/search'
import header from '@/store/modules/header'
import feedbackModal from '@/store/modules/feedbackModal'
import profile from '@/store/modules/profile'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    catalogue,
    cart,
    categories,
    auth,
    search,
    header,
    feedbackModal,
    profile,
  }
});
