<template>
  <div class="products-list">
    <div class="products-list__menu">
      <h2 class="products-list__title">{{ title }}</h2>
      <div class="products-list__options">
        <VInput class="products-list__options-item"/>
        <VSelect @sort="sortByPrice"/>
      </div>
    </div>
    <div class="products-list__list">
      <VProduct
        class="products-list__item"
        v-for="product in paginatedUsers"
        :key="product.id"
        :product_data="product"
      />
    </div>
    <div class="pages" v-if="paginatedUsers.length">
      <div class="pages__line">
        <div
          class="page__block"
          v-for="page in pages"
          :key="page"
          @click="pageClick(page)"
        >
          <div
            class="page"
            :class="{'page__selected': page === pageNumber}"
            v-if="page <= pageNumber+1 && page >= pageNumber-1">
            {{page}}
          </div>
        </div>
        <div
          v-if="pageNumber < pages"
          class="page"
        >
          ...
        </div>
        <button
          class="page page__arrow"
          @click="pageForward">
          <img :src="require('/src/assets/image/icons/arrow-pagination.svg')">
        </button>
      </div>
      <div class="total__goods">Всего
        <span> {{ sortedProducts.length || products.length }} </span>
        товар{{quantityDeclination}}
      </div>
    </div>
<!--    <div class="total__goods" v-show="!paginatedUsers.length"> <span>Временно в данной категории нет продуктов</span></div>-->
  </div>
</template>

<script>
import VProduct from '@/components/catalogue/VProduct';
import VSelect from '@/components/common/VSelect'
import VInput from '@/components/common/VInput'
import { mapGetters } from "vuex";
import {api} from "@/api/api";

export default {
  name: 'VProductsList',
  components: {
    VProduct,
    VSelect,
    VInput,
  },
  props: {
    products: {
      type: Array,
      default() {
        return []
      }
    },
    title: {
      type: String,
      default() {
        return 'Каталог товаров'
      }
    },

  },
  data() {
    return {
      sortedProducts: [],
      userPerPage: 12,
      pageNumber: 1,
    }
  },
  computed: {
    ...mapGetters([
      'allProducts'
    ]),
    quantityDeclination() {
      let number = this.sortedProducts.length || this.products.length;
      return (number%100<11 ||number%100>19)?((number%10!==1)?((number%10>1 && number%10<5)?'а':'ов'):''):'ов';
    },

    allFilteredProducts() {
      return (this.sortedProducts.length) ? this.sortedProducts : this.products;
    },
    pages() {
      return Math.ceil(this.allFilteredProducts.length / this.userPerPage);
    },
    paginatedUsers() {
      let from = (this.pageNumber - 1) * this.userPerPage;
      let to = from + this.userPerPage;
      return this.allFilteredProducts.slice(from, to)
    }
  },
  methods: {
    sortByPrice(sort) {
      if (sort === 'default') {
        this.sortedProducts = [...this.products];
      }
      if (sort === 'asc') {
        this.sortedProducts = this.products.slice().sort((a, b) => {
          return (a.price < b.price) ? -1 : (a.price > b.price) ? 1 : 0
        });
      }
      if (sort === 'decr') {
        this.sortedProducts = this.products.slice().sort((a, b) => {
          return (a.price < b.price) ? 1 : (a.price > b.price) ? -1 : 0
        });
      }
      this.pageNumber = 1;
    },
    pageClick(page) {
      this.pageNumber = page;
      window.scrollTo(0,50)
    },
    pageForward() {
      if (this.pageNumber < this.pages) {
        this.pageNumber ++;
      }
    },
  },
  watch: {
    products() {
      this.sortedProducts = [...this.products]
    },
  },

}
</script>

<style lang="scss" scoped>
.products-list {
  width: 100%;
  @include small-desktop {
    width: 700px;
    margin-right: auto;
    margin-left: auto;
  }
  @include tablet {
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
  }
  @include phone {
    max-width: 435px;
    min-width: 334px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  &__wrapper {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    @include small-desktop {
      justify-content: space-between;
    }
    @include tablet {
      justify-content: space-between;
    }

    //@include phone {
    //  max-width: 435px;
    //  margin-right: auto;
    //  margin-left: auto;
    //}

  }
  &__item {
    //width: 20%;
    margin-right: 20px;
    @include small-desktop {
      margin-right: 0;
    }
  }
  &__title {
    font-size: 2.5em;
    font-weight: 500;
    white-space: nowrap;
    margin-bottom: 7px;
    @include phone {
      font-size: 26px;
      margin-bottom: 15px;
    }
  }
  &__menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 42px;
    flex-wrap: wrap;
    @include phone {
      flex-direction: column;
      align-items: stretch;
    }
  }
  &__options {
    display: flex;
    @include phone {
      flex-direction: column;
    }
    &-item {
      margin-right: 24px;
      @include phone {
        margin-bottom: 15px;
      }
    }
  }
}
.pages {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__line {
    display: flex;
  }
}
.page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  margin-right: 10px;
  font-size: 16px;
  color: black;
  background-color: #EBEBF4;
  border-radius: 6px;
  @include phone {
    width: 30px;
    height: 30px;
  }
  &__arrow {
    border-radius: 50%;
  }
}
.page__selected {
  font-weight: 700;
  background-color: $orange-color;
}
.total__goods {
  display: flex;
  align-items: center;
  font-size: 16px;
  @include phone {
    font-size: 14px;
  }
  & > span {
    font-weight: 700;
    margin: 0 5px;
  }
}
</style>
