<template>
  <button @click="openFeedbackModal" class="main-btn">{{ title }}</button>
</template>

<script>
export default {
  name: "VMainButton",
  props: {
    formTitle: {
      type: String,
    },
    title: {
      type: String,
    },
    inputForPhone: {
      type: Boolean,
    },
    inputForQuestion: {
      type: Boolean,
    },
  },
  methods: {
    openFeedbackModal() {
      this.$store.commit('SHOW_FEEDBACK_MODAL', {
        formTitle: this.formTitle,
        inputForPhone: this.inputForPhone,
        inputForQuestion: this.inputForQuestion
      });
    }
  },
}
</script>

<style scoped lang="scss">
.main-btn {
  cursor: pointer;
  padding: 12px 36px;
  color: $black;
  background-color: #FFE9C6;
  border-radius: 6px;
}
</style>
