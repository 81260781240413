<template>
  <div id="home">
    <VCart>
      <TheSidebar
        :mainSidebarList="mainSidebarList"
        :dropDownList1="dropDownList1"
        :dropDownList2="dropDownList2"
      />
    </VCart>
  </div>
</template>

<script>
import TheSidebar from '@/components/sidebar/TheSidebar';
import VCart from '@/components/cart/VCart';


export default {
  name: 'CartPage',
  components: {
    TheSidebar,
    VCart,
  },
  data() {
    return {
      mainSidebarList: [
        { icon: 'literature.png',  title: 'Литература '},
        { icon: 'bracelets.png',  title: 'Браслеты'},
        { icon: 'amulets.png',  title: 'Амулеты'},
        { icon: 'wax.png',  title: 'Воск'},
        { icon: 'souvenirs.png',  title: 'Сувениры '},
        { icon: 'candles.png',  title: 'Свечи', active: true},
        { icon: 'tablecloths.png',  title: 'Скатерти '},
        { icon: 'taro.png',  title: 'Таро'},
        { icon: 'runes.png',  title: 'Руны'},
        { icon: 'stav.png',  title: 'Став'},
        { icon: 'powder.png',  title: 'Порошок'},
        { icon: 'oils.png',  title: 'Масла'},
      ],
      dropDownList1: [
        { title: 'Свечи' },
        { title: 'Денежная магия', active: true },
        { title: 'Любовная магия' },
        { title: 'Черная магия' },
        { title: 'Быт удача' },
        { title: 'Здоровье/Очищение/Защита' },
        { title: 'Ведьмины' },
      ],
      dropDownList2: [
        { title: 'Денежная магия', active: true },
        { title: 'Классчическая серия' },
        { title: 'Маканки'},
        { title: 'Алтарный'},
        { title: 'Цыганская серия' },
        { title: 'Магический треугольник' },
        { title: 'Восковые номерные' },
        { title: 'Немецкий воск номерные' },
        { title: 'Иерусалимские' },
        { title: 'Восковые номерные' },
        { title: 'Из вощины' },
        { title: 'Монастырские' },
        {title: 'Скрутки' },
      ],
    }
  }
}
</script>

