<template>
  <div class="side">
    <div class="sidebar__wrap" ref='sidebarWrapper' v-if="toggleDesktopSidebar">
      <aside class="sidebar" ref="sidebarAside">
        <ul class="sidebar__categories categories" ref="categories">
          <li
            :class="{categories__item: true, 'categories__item--active': activeMenuItem === category.name}"
            v-for="(category, i) in categories"
            :key="i"
          >
            <div class="sidebar__inner categories__inner">
              <div class="categories__link" @click="hideSidebar(category.name)">
                <router-link :to="`/catalogue/categories/${category.id}`">
                  <!--                <img-->
                  <!--                  class="sidebar__icon"-->
                  <!--                  :src="category.img"-->
                  <!--                  :alt="category.icon"-->
                  <!--                >-->
                  <span>{{ category.name }}</span>
                </router-link>
              </div>
              <!--            <svg-->
              <!--              ref="arrowInCategories"-->
              <!--              class="sidebar__arrow"-->
              <!--              @click="toggleMenuItem($event, category.id, category.name, category.subcategories)"-->
              <!--              v-if="category.subcategories.length"-->
              <!--              xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"/>-->
              <!--            </svg>-->
              <svg
                ref="arrowInCategories"
                class="sidebar__arrow"
                @click="toggleMenuItem($event, category.id, category.name, category.subcategories)"
                v-if="category.subcategories.length"
                width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 10L4.29135 6.29724C4.69549 5.84257 4.69549 5.15743 4.29135 4.70276L1 1" stroke-linecap="round"/>
              </svg>
            </div>
          </li>
        </ul>
      </aside>
      <SidebarList1
        ref="subcategorisList"
        v-if="subcategoriesVisible"
        :dropDownList1="subcategoriesList"
        :title="categoryTitle"
        :categoryId="categoryId"
        @closeSubcategoryList="closeSubcategoryList"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import SidebarList1 from '@/components/sidebar/SidebarList1';

export default {
  name: 'TheSidebar',
  components: {
    SidebarList1,
  },
  data() {
    return {
      categoryId: null,
      categoryTitle: '',
      subcategoriesList: [],
      subcategoriesVisible: false,




      activeMenuItem: '',
      currenSelectedCategoriesItem: '',
      currenSelectedSubcategoriesItem: '',
    }
  },
  computed: {
    ...mapGetters([
      'categories',
      'toggleDesktopSidebar',
    ]),
  },
  methods: {
    closeSubcategoryList() {
      this.subcategoriesVisible = false;
    },
    toggleMenuItem(e, categoryId, categoryTitle, subcategoriesList) {
      if (categoryTitle !== this.categoryTitle) {
        this.categoryId = categoryId;
        this.categoryTitle = categoryTitle;
        this.activeMenuItem = categoryTitle;
        this.subcategoriesList = subcategoriesList;
        this.subcategoriesVisible = true;
      } else {
        this.subcategoriesVisible = !this.subcategoriesVisible;
      }
    },

    closeSidebar(e) {
      if (this.subcategoriesVisible &&!this.$refs.subcategorisList.$el.contains(e.target) &&  !this.$refs.sidebarAside.contains(e.target)) {
        this.subcategoriesVisible = false;
        this.activeMenuItem = '';
      }
      if(!this.$refs.sidebarAside.contains(e.target)) {
        this.activeMenuItem = '';
      }
    },
    hideSidebar(categoryTitle) {
      this.subcategoriesVisible = false;
      this.activeMenuItem = categoryTitle;
    },
  },
  mounted() {
    this.currenSelectedCategoriesItem =  document.querySelector(".sidebar")
    this.currenSelectedSubcategoriesItem =  document.querySelector(".sidebar")
    this.test =  document.querySelector(".sidebar")
    document.addEventListener('click', this.closeSidebar)
  }
}
</script>

<style scoped lang="scss">
.side {
  position: sticky;
  top: 119px;
  z-index: 99;
}

.sidebar__wrap {
  position: relative;
  //width: 300px;
  width: 243px;
  //margin-right: 43px;
  height: 513px;
  @include medium-tablet {
    display: none;
  }
}
.sidebar {
  position: absolute;
  //min-height: 513px;
  //top: 68px;
  //right: 0;
  //left: 0;
  z-index: 99;
  width: auto;
  //height: 513px;
  height: 100%;
  //padding-top: 28px;
  font-size: 16px;
  //background-color: $main-bg;
  direction: rtl;
  //overflow-y: scroll;
  //overflow-x: visible;
  & > ul {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    //max-height: 300px;
    height: 100%;
    //padding-top: 10px;
    max-height: 513px;
    overflow-y: scroll;
    scrollbar-width: thin;
    overflow-x: visible;
    width: 100%;
    //transform: scaleX(-1);
    //direction: ltr;
  }
  &__inner {
    position: relative;
    direction: ltr;
  }
  &__arrow {
    height: 18px;
    margin-left: 10px;
    //margin-left: 15px;
    & path {
      stroke: white;
    }

  }

  &__record {
    height: 34px;
    padding: 8px 24px;
    margin-top: 25px;
    margin-bottom: 100px;
    margin-left: 11px;
    color: $black;
    background-color: #FFE9C6;
    border-radius: 100px;
  }
  &__icon {
    width: 17px;
    height: 17px;
    margin-right: 10px;
    margin-left: 10px;
    overflow: hidden;
  }
  &__contact {
    display: flex;
    align-items: center;
    margin-left: 11px;
    margin-top: 60px;
    a {
      font-size: 16px;
      margin-left: 10px;
    }
  }
}

.categories {
  display: inline-block;

  &__item {
    position: relative;
    //width: 150px;
    padding-right: 16px;
    border-radius: 10px;
    margin-left: 10px;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 10px;
    //&:first-child {
    //  padding-top: 0;
    //}
  }
  &__item--active {
    background-color: white;
    color: $main-bg;
    & svg {
      & path {
        stroke: black;
      }
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    //padding: 11px;
    //padding-top: 16px;
    //padding-bottom: 16px;
  }
  &__link {
    display: flex;
    align-items: center;
    text-align: left;
    width: 134px;
    & > a {
      display: flex;
      align-items: center;
      width: 100%;
      & > span {
        width: 100%;
        line-height: 21px;
      }
    }
  }
}

.subcategories-top {
  top: 0;
}

.subcategories {
  position: absolute;
  top: -9px;
  left: -103px;
  //left: 100px;
  width: auto;
  min-width: 240px;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin-left: 300px;
  background: #FFFFFF;
  border-radius: 20px;
  color: black;
  &__item {
    position: relative;
    display: inline-block;
    font-size: 15px;
    //width: 300px;
    //padding: 16px 0;
    margin-bottom: 1px;
    border-bottom: 1px solid #EAEAEA;
    overflow-y: visible;
    &:last-child {
      border: none;
      padding-bottom: 0;
    }
  }
  &__inner {
    position: relative;
    //padding: 11px;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    //justify-content: space-between;
    //width: 170px;
  }
  &__link {
    display: flex;
    align-items: center;
    text-align: left;
  }
  &__link--name {
    direction: ltr;
    font-size: 18px;
    font-weight: bold;
    white-space: nowrap;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.series {
  position: absolute;
  top: 0px;
  left: -85px;
  //left: 100px;
  width: 240px;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin-left: 300px;
  background: #FFFFFF;
  border-radius: 20px;
  color: black;
  &__item {
    display: inline-block;
    font-size: 15px;
    //padding: 11px;
    //width: 300px;
    border-bottom: 1px solid #EAEAEA;
    &:last-child {
      border: none;
      padding-bottom: 0;
    }
  }
  &__inner {
    display: flex;
    align-items: center;
    //padding-top: 16px;
    //padding-bottom: 16px;
  }
  &__link {
    display: flex;
    align-items: center;
  }
}


</style>
