<template>
  <div class="catalog" v-if="similarProducts.length">
    <div class="container catalog__container">
      <div class="catalog__menu">
        <h2 class="catalog__title">Похожие товары</h2>
      </div>
      <div class="catalog__list">
        <VCatalogItem
          class="catalog__item"
          v-for="product in similarProducts.slice(0, similarProductsLength)"
        :key="product.id"
        :product_data="product"
        />
      </div>
      <button
        class="catalog__button"
        v-if="isVisibleButton"
        @click="clickShowAll"
      >
        Смотреть все
      </button>
    </div>
  </div>
</template>

<script>
import VCatalogItem from '@/components/index/VCatalogItem'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'SimilarProductsBlock',
  components: {
  VCatalogItem,
  },
  props: {
    idSimilar: {
      default() {
        return 1
      },
    },
    isVisibleButtonProps: {
      default() {
        return false
      },
    },
    limitProductsProps: {
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      limitDisplayProducts: 2,
      isVisibleButton: this.isVisibleButtonProps,
    }
  },
  computed: {
  ...mapGetters([
    'similarProducts',
  ]),
    similarProductsLength() {
    return this.isVisibleButton ? this.limitProductsProps : this.recommended.length;
    },
    limitProducts() {
    return this.recommended.slice(0, this.limitDisplayProducts)
    }
 },
  methods: {
    ...mapActions([
      'GET_SIMILAR_PRODUCTS'
    ]),
    clickShowAll() {
      this.limitDisplayProducts = this.recommended.length;
      this.isVisibleButton = false;
    }
  },
}
</script>

<style scoped lang="scss">
.catalog {
  margin-left: 10%;
  @include phone {
    margin-left: 0;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    @include phone {
      justify-content: space-between;
    }
  }
  &__item {
    margin-right: 20px;
    @include phone {
      margin-right: 0;
    }
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    font-size: 16px;
    color: $dark-grey;
    border: 2px solid $dark-grey;
    border-radius: 5px;
  }
  &__title {
    font-size: 2.5em;
    font-weight: 400;
    margin-bottom: 36px;
    @include phone {
      font-size: 26px;
    }
  }

  &__menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__options {
    display: flex;

    &-item {
      margin-right: 24px;
    }
  }
}
</style>

