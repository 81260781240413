<template>
  <section class="hero">
    <div class="container hero__container">
      <slot></slot>
      <div class="hero__slider">
        <div :navigation="true" ref="bannerSwiper" class="swiper-container hero__slider-container">
          <div :navigation="true" class="swiper-wrapper">

            <div
              class="swiper-slide banner__slide"
              v-for="banner in banners"
              :key="banner.id"
            >
              <div :style="{ 'background-image': 'url(' + banner.img + ')' }" class="hero__banner">
                <div class="hero__details">
                  <a :href="banner.button_link" target="_blank">{{ banner.button_name }}</a>
                </div>
              </div>
            </div>

          </div>
          <div class="swiper-button-next button__next" style="z-index: 1"></div>
          <div class="swiper-button-prev button__prev" style="z-index: 1"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper, { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { api } from '@/api/api';

export default {
  name: 'VHero',
  data() {
    return {
      banners: [],
    }
  },
  methods: {
    getDataBanners() {
      api.get(`api/banners`)
        .then(response => {
          this.banners = response.data.data;
        })
    }
  },
  mounted() {
    new Swiper(this.$refs.bannerSwiper, {
      modules: [Navigation, Pagination],
      slidesPerView: 1,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      scrollbar: {
        el: '.swiper-scrollbar',
      },
    })
    this.getDataBanners();
  },
}
</script>

<style scoped lang="scss">
.hero {
  &__container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    min-height: 513px;
    //max-height: 610px;
    //padding-top: 20px;
    padding-bottom: 30px;
    padding: 0 15px;
    //overflow-y: hidden;
    @include phone {
      min-height: 0;
      padding-top: 0;
    }
  }
  &__banner {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 600px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    //margin-top: 10px;
    border-radius: 10px;
    @include phone {
      height: 220px;
    }
  }
  &__details {
    position: absolute;
    bottom: 103px;
    right: 180px;
    padding: 15px 57px;
    font-size: 16px;
    color: $black;
    background-color: $brend-color;
    border-radius: 6px;
    @include phone {
      right: 10%;
      bottom: 10%;
      font-size: 12px;
      padding: 5px 15px;
    }
  }
}
.swiper-slide {
  z-index: 1;
}
.hero__slider {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.hero__slider-container {
  height: 100%;
}

.button__prev {
  color: white;
  top: 50%;
  left: 0%;
  &::after {
    font-size: 35px;
    @include phone {
      font-size: 20px;
    }
  }
  @include phone {
    top: 50%;
    left: -2%;
  }
}
.button__next {
  color: white;
  top: 50%;
  right: 0%;
  &::after {
    font-size: 35px;
    @include phone {
      font-size: 20px;
    }
  }
  @include phone {
    top: 50%;
    right: -2%;
  }
}
</style>
