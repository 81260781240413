<template>
  <div class="notification">
    <transition
      name="notification-animate"
      class="notification__animate"
    >
      <div class="notification__content" v-if="notification.length">
        <img src="@/assets/image/icons/product-check.svg" alt="check mark">
        <span class="notification__info">{{ notification }}</span>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'AddNotification',
  computed: {
    ...mapGetters([
      'notification'
    ]),
  },
}
</script>

<style scoped lang="scss">
.notification {
  position: fixed;
  top: 30px;
  right: 15%;
  z-index: 9999;
  &__content {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 100px;
    background-color: $main-dark;
    margin-bottom: 10px;
    opacity: 0.9;
    border-radius: 10px;
    & img {
      margin-right: 10px;
    }
  }

}
.notification-animate {
  &-enter {
    -webkit-transform: translateY(100px);
    transform: translateY(50px);
    opacity: 0;
    display: flex;
    flex-direction: column-reverse;

  }
  &-enter-active {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;

  }
  &-enter-to {
    opacity: 1;
  }
  &-leave {
    height: 50px;
    opacity: 1;
  }
  &-leave-active {
    -webkit-transition: transform .6s ease, opacity .6s , height .6s .2s;
    -moz-transition: transform .6s ease, opacity .6s , height .6s .2s;
    transition: transform .6s ease, opacity .6s , height .6s .2s;

  }
  &-leave-to {
    height: 0;
    transform: translateY(-100px);
    opacity: 0;
  }
}
</style>
