export default {
  state: {
    showLoginModal: false,
    orderWithAuth: false,
    isWholesale: false,
  },
  getters: {
    showLoginModal(state) {
      return state.showLoginModal;
    },
    orderWithAuth(state) {
      return state.orderWithAuth;
    },
    isWholesale(state) {
      return state.isWholesale;
    }
  },

  mutations: {
    SHOW_FORM_LOGIN: (state) => {
      state.showLoginModal = true;
    },
    HIDE_FORM_LOGIN(state) {
      state.showLoginModal = false;
    },
    CHANGE_AUTH_STATUS(state, status) {
      state.orderWithAuth = status;
    },
    SET_AUTH_STATUS(state) {
      if(localStorage.getItem('access_token')) {
        state.orderWithAuth = true;
      } else state.orderWithAuth = false;
    }
  }
}
