export default {
  state: {
    isSearchVisible: false,
  },
  getters: {
    isSearchVisible(state) {
      return state.isSearchVisible;
    },
  },

  mutations: {
    SHOW_SEARCH: (state) => {
      state.isSearchVisible = true;
    },
    TOGGLE_SEARCH(state) {
      state.isSearchVisible = !state.isSearchVisible;
    },
    HIDE_SEARCH: (state) => {
      state.isSearchVisible = false;
    },
  }
}
