import { render, staticRenderFns } from "./SliderPromotional.vue?vue&type=template&id=1c6870ae&scoped=true&"
import script from "./SliderPromotional.vue?vue&type=script&lang=js&"
export * from "./SliderPromotional.vue?vue&type=script&lang=js&"
import style0 from "./SliderPromotional.vue?vue&type=style&index=0&id=1c6870ae&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c6870ae",
  null
  
)

export default component.exports