<template>
  <div id="home-page">
    <VHero>
      <TheSidebar/>
    </VHero>
    <VPopulars />
    <SliderPromotional :title="SliderPromotionalFirstTitle" />
    <VCategories />
    <VCatalog />
    <SliderPromotional :title="SliderPromotionalSecondTitle" /></div>
</template>

<script>
import VueMeta from 'vue-meta'
import VHero from '@/components/index/VHero';
import TheSidebar from '@/components/sidebar/TheSidebar';
import VCategories from '@/components/index/VCategories';
import SliderPromotional from '@/components/index/SliderPromotional';
import VPopulars from '@/components/index/VPopulars';
import VCatalog from '@/components/index/VCatalog';
import { mapActions} from 'vuex';

export default {
  name: 'HomePage',
  metaInfo() {
    return {
      title: 'Откройте Путь К Тайнам И Духовности С Нашим Уникальным Ассортиментом',
      titleTemplate: '%s | Таро - Онлайн Магазин Эзотерических Товаров',
      htmlAttrs: {
        lang: 'ru',
        amp: true
      },
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Добро пожаловать в мир волшебства и загадок! Наш онлайн магазин Таро, свечей и эзотерических товаров предлагает вам уникальную возможность окунуться в мир древних практик и духовных открытий. Раскройте свой потенциал с помощью разнообразных колод Таро, зажгите магические свечи для создания особой атмосферы и исследуйте удивительный ассортимент эзотерических товаров, включая кристаллы, амулеты и колдовские инструменты. Наши высококачественные продукты помогут вам обрести гармонию, привлечь благополучие и раскрыть тайны мира. Мы доставляем по всему миру, чтобы каждый мог проникнуться магией и духовностью. Присоединяйтесь к нам сегодня и откройте врата к новым возможностям!',
        }
      ]
    }
  },
  components: {
    TheSidebar,
    VHero,
    VPopulars,
    SliderPromotional,
    VCategories,
    VCatalog,
  },
  data() {
    return {
      SliderPromotionalFirstTitle: 'Акции',
      SliderPromotionalSecondTitle: 'Статьи',
    }
  },
  methods: {
    ...mapActions([
      'GET_PRODUCTS_CART'
    ])
  },
  mounted() {
    this.GET_PRODUCTS_CART()
  }
}
</script>

