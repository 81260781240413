<template>
  <footer class="footer">
    <div class="container footer__container">
      <div class="footer__wrapper">
        <div class="footer__block">
          <div class="footer__logo">
            <img :src="header.logo" alt="Logo" height="100px">
          </div>
          <div class="footer__communication">
            <span>Остались вопросы?</span>
            <VMainButton
              :title="'Задать вопрос'"
              :formTitle="'Задать вопрос'"
              :inputForQuestion="true"
              :inputForPhone="true"
            />
          </div>
        </div>
        <div class="footer__main-block">
          <div class="footer__menu">
            <div>
              <span class="footer__menu-item">
                <router-link to="/">Главная</router-link>
              </span>
              <span class="footer__menu-item">
                <router-link to="/catalogue/">Каталог</router-link>
              </span>
              <span class="footer__menu-item">
                <router-link to="/about">О нас</router-link>
              </span>
            </div>
            <div>
              <span class="footer__menu-item">
                <router-link to="/order">Оплата</router-link>
              </span>
              <span class="footer__menu-item">
                <router-link to="/contacts">Контакты</router-link>
              </span>
            </div>
          </div>
          <div class="footer__wrap">
            <div
              v-for="(contact, i) in contacts"
              :key="i"
              class="footer__contacts"
            >
              <div class="footer__address-title">{{ contact.title }}</div>
              <div class="footer__address-info">{{ contact.info }}</div>
            </div>
          </div>
        </div>
        <div class="footer__communication footer__communication--mobile">
          <span>Остались вопросы?</span>
          <VMainButton
            :title="'Задать вопрос'"
            :formTitle="'Задать вопрос'"
            :inputForQuestion="true"
            :inputForPhone="true"
          />
        </div>
        <hr>
        <!-- <div class="footer__additionally"> -->
        <!--   <span >Политика конфиденциальности</span> -->
        <!--   <span>Сайт разработан компанией A-LUX</span> -->
        <!-- </div> -->
      </div>
    </div>
  </footer>
</template>

<script>
import VMainButton from '../common/VMainButton'
import {mapGetters} from 'vuex'


export default {
  name: 'TheFooter',
  components: {
    VMainButton,
  },
  data() {
    return {
      contacts: [
        {
          title: 'Контактный адрес:',
          info: 'РК, г.Алматы, ул. Сатпаева, 63',
        },
        {
          title: 'Контактная почта:',
          info: 'info@taroshop.kz',
        },
        {
          title: 'Контактный номер:',
          info: '+7 707 188 7000',
        },
      ]
    }
  },
  computed: {
    ...mapGetters([
      'header',
    ]),
  },
}
</script>
<style scoped lang="scss">
.footer {
  padding: 60px 10px;
  margin-top: 64px;
  background-color: $main-dark;
  @include phone {
    padding: 30px 15px;
    margin-top: 35px;
  }
  &__container {
    padding: 0 15px;
  }
  &__logo {
    @include phone {
      width: 100px;
      height: 100px;
    }
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin-left: auto;
    @include phone {
      display: flex;
      flex-direction: column;
      align-items: end;
    }
  }
  & hr {
    width: 1160px;
    height: 0px;
    opacity: 0.2;
    border: 1px solid #FFFFFF;
  }
  &__block {
    display: flex;
    justify-content: space-between;
    @include phone {
      //margin-left: 30px;
    }
  }
  &__main-block {
    display: flex;
    //justify-content: space-between;
    @include phone {
      justify-content: space-between;
      padding: 0 10px;
      //margin: 0 30px 30px;
    }
  }
  &__communication {
    align-items: center;
    @include phone {
      display: none;
    }
    & span {
      margin-right: 24px;
      font-size: 24px;
      font-weight: bold;
    }
  }
  &__communication--mobile {
    display: none;
    & > span {
      @include phone {
        margin-bottom: 10px;
      }
    }
    & > button {
      @include phone {
        margin-bottom: 10px;
      }
    }
    @include phone {
      display: flex;
      flex-direction: column;
    }
  }
  &__btn {
    @include phone {
      width: 100%;
      margin-top: 15px;
      margin-bottom: 30px;
    }
  }
  &__address {
    display: flex;
    flex-direction: column;
  }
  &__additionally {
    display: flex;
    justify-content: space-between;
    margin-top: 48px;
    & > span {
      @include phone {
        margin-bottom: 15px;
      }
    }
    @include phone {
      flex-direction: column;
      margin-top: 30px;
    }
  }
  &__contacts {
    display: flex;
    flex-direction: column;
    max-width: 180px;
    font-weight: 600;
    line-height: 17.44px;
    @include phone {
      margin-bottom: 30px;
    }
  }
  &__address-title {
    text-align: end;
    font-weight: 600;
    margin-bottom: 8px;
    white-space: nowrap;
  }
  &__address-info {
    text-align: end;
    color: $brend-color;
  }
  &__main-block {
    margin: 50px 15px;
    @include phone {
      margin: 0;
    }
  }
  &__menu {
    & > div {
      @include phone {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
      }
    }
  }

  &__menu-item {
    display: inline-block;
    margin-right: 40px;
    margin-bottom: 24px;
    @include phone {
      margin-bottom: 15px;
    }
  }
}

</style>

