import {api} from "@/api/api";

export default {
  state: {
    // index
    allProducts: [],
    actions: [],
    popularCategories: [],

    // slider
    recommended: [],
    discounted: [],
    specialOffer: [],
    superPrice: [],
    similarProducts: [],
  },
  getters: {
    // index
    allProducts(state) {
      return state.allProducts;
    },
    actions(state) {
      return state.actions;
    },
    popularCategories(state) {
      return state.popularCategories;
    },
    // slider
    recommended(state) {
      return state.recommended;
    },
    discounted(state) {
      return state.discounted;
    },
    specialOffer(state) {
      return state.specialOffer;
    },
    superPrice(state) {
      return state.superPrice;
    },
    similarProducts(state) {
      return state.similarProducts;
    },

  },
  mutations: {
    // index
    SET_PRODUCTS_TO_STATE: (state, allProducts) => {
      state.allProducts = allProducts;
    },
    SET_ACTIONS_TO_STATE: (state, actions) => {
      state.actions = actions;
    },
    SET_POPULARS_TO_STATE: (state, popularCategories) => {
      state.popularCategories = popularCategories;
    },

    //slider
    SET_RECOMMENDED_TO_STATE: (state, recommended) => {
      state.recommended = recommended;
    },
    SET_DISCOUNTER_TO_STATE: (state, discounted) => {
      state.discounted = discounted;
    },
    SET_SPECIALOFFER_TO_STATE: (state, specialOffer) => {
      state.specialOffer = specialOffer;
    },
    SET_SUPERPRICE_TO_STATE: (state, superPrice) => {
      state.superPrice = superPrice;
    },
    SET_SIMILAR_TO_STATE: (state, similarProducts) => {
      state.similarProducts = similarProducts;
    },
  },
  actions: {
    // index
    GET_PRODUCTS({commit}) {
      return api.get('api/catalogue')
        .then(allProducts => {
          commit('SET_PRODUCTS_TO_STATE', allProducts.data.data);
          return allProducts;
        })
        .catch(error => {
          commit('SET_PRODUCTS_TO_STATE', []);
        })
    },
    GET_ACTIONS({commit}) {
      return api.get('api/actions')
        .then(actions => {
          commit('SET_ACTIONS_TO_STATE', actions.data.data);
          return actions;
        })
        .catch(error => {
          commit('SET_ACTIONS_TO_STATE', []);
        })
    },

    GET_POPULARS({commit}) {
      return api.get('api/popular-categories')
        .then(populars => {
          commit('SET_POPULARS_TO_STATE', populars.data.data);
          return populars;
        })
        .catch(error => {
          commit('SET_POPULARS_TO_STATE', []);
        })
    },

    // slider
    GET_RECOMMENDED({commit}) {
      return api.get('api/recommended')
        .then(recommended => {
          commit('SET_RECOMMENDED_TO_STATE', recommended.data.data);
          return recommended;
        })
        .catch(error => {
          commit('SET_RECOMMENDED_TO_STATE', []);
        })
    },
    GET_DISCOUNTER({commit}) {
      return api.get('api/discounted')
        .then(discounted => {
          commit('SET_DISCOUNTER_TO_STATE', discounted.data.data);
          return discounted;
        })
        .catch(error => {
          commit('SET_DISCOUNTER_TO_STATE', []);
        })
    },
    GET_SPECIALOFFER({commit}) {
      return api.get('api/special-offer')
        .then(specialOffer => {
          commit('SET_SPECIALOFFER_TO_STATE', specialOffer.data.data);
          return specialOffer;
        })
        .catch(error => {
          commit('SET_SPECIALOFFER_TO_STATE', []);
        })
    },
    GET_SUPERPRICE({commit}) {
      return api.get('api/super-price')
        .then(superPrice => {
          commit('SET_SUPERPRICE_TO_STATE', superPrice.data.data);
          return superPrice;
        })
        .catch(error => {
          commit('SET_SUPERPRICE_TO_STATE', []);
        })
    },
    GET_SIMILAR_PRODUCTS({commit}, id) {
      return api.get(`api/catalogue/${id}/similar`)
        .then(similarProducts => {
          commit('SET_SIMILAR_TO_STATE', similarProducts.data.data);
          return similarProducts;
        })
        .catch(err => {
          commit('SET_SIMILAR_TO_STATE', []);
        })
    },
  }
}
