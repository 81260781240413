<template>
  <div class="overlay" @mousedown.self="closeModal">
    <div class="modal">
      <div class="modal-inner">
        <div class="modal-close" @click="closeModal">&times;</div>
        <div class="modal-body">
          <div class="modal-text">Спасибо за Вашу покупку</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped lang="scss">
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  position: relative;
  padding: 25px;
  max-width: 500px;
  width: 100%;
  background-color: $main-dark;
  opacity: 0.9;
  border-radius: 10px;
  cursor: default;

  &-close {
    cursor: pointer;
    font-size: 48px;
    line-height: 48px;
    color: #fff;
    position: absolute;
    right: 15px;
    top: 20px;
  }

  &-text {
    padding-right: 20px;
    font-size: 24px;
  }
}
</style>
