<template>
  <div class="product-page">
    <div class="container product-page__container">
      <div class="product-page__wrapper">
        <TheSidebar/>
        <div class="product">
          <PreLoader class="product-page__preloader" v-if="loadingStatus"/>
          <div v-else >
            <h1 class="product__title">Страница продукта</h1>
            <div class="product__links">
              <router-link to="/">Главная</router-link>
              <span> / {{ product.name }}</span>
            </div>
            <div class="product__wrapper">
              <ProductSlider class="product__slider" :productImages="product.photos" :isAction="product.discount>0"/>
              <div class="product__info">
                <h2 class="product__name">{{ product.name }}</h2>
                <div class="product__available">
                  <div class="available" v-if="product.in_stock">
                    <img src="@/assets/image/icons/product-check.svg" alt="product available">
                    В наличии
                  </div>
                  <div class="not-available" v-else >Нет в наличии</div>
                  <div class="product__rating">
                    <span>{{product.rating}}</span>
                    <img class="product__rating-img" src="@/assets/image/icons/star-product-rating.svg" alt="">
                    <div class="product__rating-info">
                      <img src="@/assets/image/icons/rating-info.svg" alt="" ref="iconRating" @click="showRatingNotification">
                      <RatingNotification v-if="ratingNotification" class="product__rating-notification"/>
                    </div>
                  </div>
                </div>
                <div @click="clickNotify" class="product__notify" v-if="!product.in_stock">Уведомить о поступлении</div>
                <span
                  class="product__price-old"
                  v-if="product.discount > 0"
                >
                {{priceBeforeDiscount(product.price, product.discount) }} тг
              </span>
                <div class="product__price-new">
                  <span>{{ formatPrice(product.price) }} тг </span>
                  <img src="@/assets/image/icons/product-info.svg" @click="showSaleNotification" ref="iconSale">
                  <SaleNotification v-if="saleNotification" class="product__sale-notification"/>
                </div>
                <div v-if="this.profileData.wholesaleCustomer" class="product__price-new product__price-opt">
                  <span>опт. {{ formatPrice(product.wholesale_price) }} тг</span>
                  <img src="@/assets/image/icons/product-info.svg" @click="showSaleNotificationWholesale" ref="iconSale">
                  <saleNotificationWholesale v-if="saleNotificationWholesale" class="product__sale-notification"/>
                </div>
                <div class="product__counter counter">
                  <button class="counter__buttons counter__decr" @click="productQuantityDecr">-</button>
                  <span class="counter__quantity">{{ productQuantity }}</span>
                  <button class="counter__buttons counter__inc" @click="productQuantityInc">+</button>
                </div>
                <button
                  :class="{'product__add-btn': true, 'product__add-btn--disabled': !product.in_stock }"
                  @click="addToCart({product: product, quantity: productQuantity})"
                  :disabled="!product.in_stock || inCart"
                >
                  <img src="@/assets/image/icons/product-cart.svg">
                  <span v-html="buttonText"></span>
                </button>
                <div class="product__description description">
                  <h3 class="description__title">Характеристики</h3>
                  <p class="description__text">
                    {{ product.characteristics }}
                  </p>
                </div>
              </div>
            </div>
            <div class="product__description description description--mobile">
              <h3 class="description__title">Характеристики</h3>
              <p class="description__text">
                {{ product.characteristics }}
              </p>
            </div>
            <div class="product__about about">
              <div class="about__title">О товаре</div>
              <div class="about__text">
                {{ product.description}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <MainProductSlider titleSlider='Рандомные товары из этой категории' :contentSlider="recommended" />
      <TextPageBottom />
    </div>
  </div>
</template>

<script>
import VueMeta from 'vue-meta'
import TheSidebar from '@/components/sidebar/TheSidebar';
import ProductSlider from '@/components/product/ProductSlider';
import MainProductSlider from '@/components/common/MainProductSlider';
import TextPageBottom from '@/components/common/TextPageBottom';
import formatPrice from '@/mixins/formatPrice'
import { api } from '@/api/api';
import RatingNotification from '@/components/common/RatingNotification';
import SaleNotification from '@/components/common/SaleNotification';
import SaleNotificationWholesale from '@/components/common/SaleNotificationWholesale';
import {mapActions, mapGetters} from 'vuex';
import PreLoader from "@/components/common/PreLoader";

export default {
  name: 'ProductPage',
  metaInfo() {
    return {
      title: `${this.product.name} - купить онлайн`,
      titleTemplate: '%s | Таро - Онлайн Магазин Эзотерических Товаров',
      htmlAttrs: {
        lang: 'ru',
        amp: true
      },
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: `${this.product.name} купить онлайн в Онлайн Магазин Эзотерических Товаров Таро. Также товары похожие на ${this.product.name}`
        }
      ]
    }
  },
  components: {
    PreLoader,
    SaleNotification,
    SaleNotificationWholesale,
    RatingNotification,
    TheSidebar,
    MainProductSlider,
    TextPageBottom,
    ProductSlider,
  },
  mixins: [formatPrice],
  data() {
    return {
      loadingStatus: false,
      inCart: false,
      buttonText: 'Добавить в корзину',
      product: {},
      ratingNotification: false,
      saleNotification: false,
      saleNotificationWholesale: false,
      productQuantity: 1,
    }
  },
  computed: {
    ...mapGetters([
      'recommended',
      'profileData',
      'orderWithAuth',
    ]),
  },
  methods: {
    ...mapActions([
      'ADD_PRODUCT_TO_CART',
      'GET_PROFILE_DATA',
    ]),
    setActiveTab(tab) {
      this.orderData.full_name = this.profileData.name;
      this.orderData.phone_number = this.profileData.phone_number;
      this.orderData.email = this.profileData.email;
    },
    getProfileData() {
      if(this.orderWithAuth) {
        this.GET_PROFILE_DATA();
      }
    },
    clickNotify() {
      this.$store.commit('SHOW_FEEDBACK_MODAL', {
        formTitle: 'Уведомить о поступлении',
        inputForQuestion: false,
        inputForPhone: true,
      })
    },
    getProduct() {
      this.loadingStatus = true;
      api.get(`api/catalogue/${this.$route.params.id}`)
        .then(response => {
          this.product = response.data.data;
          this.loadingStatus = false;
        })
    },
    showRatingNotification() {
      this.ratingNotification = !this.ratingNotification;
    },
    showSaleNotification() {
      this.saleNotification = !this.saleNotification;
    },
    showSaleNotificationWholesale() {
      this.saleNotificationWholesale = !this.saleNotificationWholesale;
    },
    hideRatingNotification(e) {
      if (e.target !== this.$refs.iconRating) {
        this.ratingNotification = false
      }
    },
    hideSaleNotification(e) {
      if (e.target !== this.$refs.iconSale) {
        this.saleNotification = false
      }
    },
    hideSaleNotificationWholesale(e) {
      if (e.target !== this.$refs.iconSale) {
        this.saleNotificationWholesale = false
      }
    },
    productQuantityInc() {
      this.productQuantity += 1;
    },
    productQuantityDecr() {
      if (this.productQuantity > 1) {
        this.productQuantity -= 1;
      }
    },
    priceBeforeDiscount(price, discount) {
      const priceWithoutDiscount = Math.ceil((price * 100) / (100 - discount));
      return this.formatPrice(priceWithoutDiscount);
    },
    addToCart({product: product, quantity: productQuantity}) {
      this.ADD_PRODUCT_TO_CART({product: product, quantity: productQuantity});
      this.buttonText = '<span @click="this.$router.push({name:"cart"}">В корзине</span>';
      this.inCart = true
    }
  },
  watch: {
    '$route.params.id': {
      handler: function() {
        this.productQuantity = 1;
        if (this.$route.params.id) {
          this.getProduct();
        }
        this.buttonText = 'Добавить в корзину';
        this.inCart = false;
      },
    }
  },
  created() {
    this.getProduct();
  },
  mounted() {
    window.addEventListener('click', this.hideRatingNotification);
    window.addEventListener('click', this.hideSaleNotification);
    this.getProfileData();
  },
  destroyed() {
    window.removeEventListener('click', this.hideRatingNotification);
    window.removeEventListener('click', this.hideSaleNotification);
  }

}
</script>

<style scoped lang="scss">
.product-page {
  &__container {
    padding: 0 15px;
  }
  &__wrapper {
    display: flex;
    align-items: stretch;
    margin-bottom: 81px;
    @include phone {
      margin-bottom: 30px;
    }
  }
}

.product {
  width: 100%;
  &__info {
    max-width: 314px;
  }
  &__wrapper {
    display: flex;
    //justify-content: space-between;
    padding-bottom: 55px;
    border-bottom: 1px solid $grey-line;
    @include phone {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
  &__slider {
    margin-right: 49px;
    @include phone {
      margin-right: 15px;
    }
  }
  &__title {
    margin-bottom: 15px;
    font-weight: 500;
  }
  &__rating-info {
    position: relative;
    width: 13px;
    height: 13px;
  }
  &__rating-notification {
    left: -123px;
    top: 25px;
  }
  &__sale-notification {
    left: 54px;
    top: 49px;
  }
  &__links {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 48px;
    & span {
      color: $orange-color;
    }
  }
  &__name {
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 28px;
    @include phone {
      font-size: 16px;
      font-weight: 500;
    }
  }
  &__available {
    display: flex;
    //justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    & .available {
      font-size: 16px;
      @include phone {
        font-size: 12px;
        white-space: nowrap;
      }
    }
    & .not-available {
      font-size: 16px;
      color: #808080;
      @include phone {
        font-size: 8px;
        line-height: 13px;
        white-space: nowrap;
      }
    }
  }
  &__rating {
    display: flex;
    align-items: center;
    margin-left: 20px;
    & > span {
      font-size: 15px;
      line-height: 19px;
    }
    @include phone {
      font-size: 10px;
    }
    &-img {
      margin: 0 5px;
    }
  }
  &__notify {
    font-size: 16px;
    color: $orange-color;
    margin-bottom: 21px;
    @include phone {
      font-size: 8px;
      line-height: 11px;
      margin-bottom: 10px;
      white-space: nowrap;
    }
  }
  &__price-old {
    font-size: 15px;
    text-decoration: line-through;
    color: #808080;
    margin-bottom: 6px;
    @include phone {
      font-size: 12px;
    }
  }
  &__price-new {
    position: relative;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 30px;
    & > img {
      @include phone {
        width: 12px;
        height: 12px;
      }
    }
    @include phone {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
  &__price-opt {
    position: relative;
    font-size: 36px;
    font-weight: 700;
    margin-top: -15px;
    & > img {
      @include phone {
        width: 12px;
        height: 12px;
      }
    }
    @include phone {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
  &__add-btn {
    display: flex;
    align-items: center;
    padding: 17px 24px;
    font-size: 16px;
    margin-top: 35px;
    margin-bottom: 50px;
    background-color: $orange-color;
    color: black;
    min-width: 100px;
    border-radius: 10px;
    @include phone {
      font-size: 10px;
      width: 150px;
      padding: 10px 7px;
      margin-top: 25px;
      border-radius: 6px;
      margin-bottom: 0px;
    }
    &:active {
      background-color: $orange-btn-active;
    }
    &--disabled {
      background-color: #9d7f63f2;
      &:active {
        background-color: #9d7f63f2;
      }
    }
    & > span {
      width: 143px;
      margin-right: 15px;
    }
    & > img {
      margin-right: 9px;
      @include phone {
        margin-right: 10px;
      }
      @include phone {
        width: 17px;
        height: 15px;
      }
    }
    & > span {
      white-space: nowrap;
    }
  }
}

.counter {
  display: flex;
  align-items: center;
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    font-weight: 700;
    background-color: #F4F3F8;
    border-radius: 10px;
    @include phone {
      width: 25px;
      height: 25px;
    }
    &:active {
      background-color: $orange-color;
    }
  }
  &__quantity {
    margin: 0 14px;
  }
}
.description {
  margin-top: 50px;
  border-top: 1px solid $grey-line;
  @include phone {
    display: none;
    margin-top: 15px;
    border-top: none;
  }
  &__title {
    margin-top: 50px;
    margin-bottom: 32px;
    font-size: 22px;
    font-weight: 700;
    @include phone {
      font-size: 16px;
      margin-bottom: 5px;
      margin-top: 0;
    }
  }
  &__text {
    max-width: 280px;
    font-size: 16px;
    line-height: 30px;
    text-align: left;
    white-space: pre-line;
    @include phone {
      font-size: 12px;
    }
  }
}
.description--mobile {
  display: none;
  @include phone {
    display: block;
  }
}
.about {
  &__title {
    font-size: 22px;
    margin-top: 40px;
    margin-bottom: 30px;
    @include phone {
      font-size: 16px;
      margin-top: 15px;
      margin-bottom: 5px;
    }
  }
  &__text {
    font-size: 16px;
    @include phone {
      font-size: 12px;
    }
    p {
      margin-bottom: 10px;
    }
  }
}
</style>

